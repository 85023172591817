import {
    GET_ALL_ARTICLES,
    GET_ALL_ARTICLES_UPDATED,
    GET_ALL_ARTICLES_TAG,
    // GET_ALL_ADVERTISEMENTS_PAGE,
    ALL_ARTICLES_ERROR,
    GET_ARTICLE,
    GET_CASE_COUNT,
    GET_MARKETING_COUNT,
    GET_MARKETING_COUNT_ERROR,
    GET_ARTICLE_ERROR,
    GET_RESEARCH_ARTICLES,
    GET_CASE_STUDY_ARTICLES,
    GET_RESEARCH_ARTICLES_ERROR,
    GET_ARTICLE_COUNT,
    GET_ARTICLE_COUNT_ERROR,
    GET_PODCASTS,
    GET_ALL_PODCASTS,
    GET_PODCAST_COUNT,
    ADD_PODCAST,
    UPDATE_PODCAST,
    DELETE_PODCAST,
    SEARCH_PODCAST_PAGE,
    GET_CE,
    GET_CE_COUNT,
    SEARCH_CE_PAGE,
    GET_INFORMATION,
    GET_INFORMATION_COUNT,
    SEARCH_INFORMATION_PAGE,
    GET_OTHER,
    GET_OTHER_COUNT,
    SEARCH_OTHER_PAGE,
    ADD_ARTICLE,
    ADD_ARTICLE_ERROR,
    SEARCH_CASE_PAGE,
    SEARCH_MARKETING_PAGE,
    UPDATE_ARTICLE,
    DELETE_ARTICLE,
    SELECT_ARTICLE,
    UPDATE_ARTICLE_ERROR,
    DELETE_ARTICLE_ERROR,
    // GET_ALL_ADVERTISEMENTS_PAGE_ERROR,
    GET_FEATURED_ARTICLES,
    GET_FEATURED_ARTICLES_ERROR,
    GET_CASE_STUDY_ARTICLES_ERROR,
    SET_FEATURED_ARTICLE,
    SET_FEATURED_ARTICLE_ERROR,
    SET_FEATURED_ARTICLES_FALSE,
    SET_FEATURED_ARTICLES_FALSE_ERROR,
    GET_MARKETING_MATERIALS_ARTICLES,
    GET_MARKETING_MATERIALS_ARTICLES_ERROR,
    SEARCH_CASE_PAGE_ERROR,
    SEARCH_MARKETING_PAGE_ERROR,
    GET_CASE_COUNT_ERROR,
    GET_FEATURED_EBOOKS,
    SET_FEATURED_EBOOK,
    SET_FEATURED_EBOOKS_FALSE,
    GET_FEATURED_EBOOKS_ERROR,
    SET_FEATURED_EBOOK_ERROR,
    SET_FEATURED_EBOOKS_FALSE_ERROR,
    GET_PODCASTS_ERROR,
    GET_ALL_PODCASTS_ERROR,
    GET_PODCAST_COUNT_ERROR,
    ADD_PODCAST_ERROR,
    UPDATE_PODCAST_ERROR,
    DELETE_PODCAST_ERROR,
    SEARCH_PODCAST_PAGE_ERROR,
    GET_CE_ERROR,
    GET_CE_COUNT_ERROR,
    SEARCH_CE_PAGE_ERROR,
    GET_INFORMATION_ERROR,
    GET_INFORMATION_COUNT_ERROR,
    SEARCH_INFORMATION_PAGE_ERROR,
    GET_OTHER_ERROR,
    GET_OTHER_COUNT_ERROR,
    SEARCH_OTHER_PAGE_ERROR,
    GET_ARTICLE_PRODUCTS,
    GET_ARTICLE_PRODUCTS_ERROR,
    UPDATE_ARTICLE_PRODUCT_MAP,
    UPDATE_ARTICLE_PRODUCT_MAP_ERROR,
    DELETE_ARTICLE_PRODUCT_ASSOCIATION,
    DELETE_ARTICLE_PRODUCT_ASSOCIATION_ERROR,
    GET_EBOOKS,
    GET_EBOOKS_ERROR,
    SEARCH_ALL_ARTICLES_PAGE,
    SEARCH_ALL_ARTICLES_PAGE_ERROR,
    UNPUBLISH_ARTICLES,
    UNPUBLISH_ARTICLES_ERROR,
    GET_ALL_FEATURED_RECENT_ARTICLES,
    GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY,
    DELETE_FEATURED_RECENT_ARTICLES,
    ADD_FEATURED_RECENT_ARTICLE,
    GET_ALL_FEATURED_RECENT_ARTICLES_ERROR,
    GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY_ERROR,
    DELETE_FEATURED_RECENT_ARTICLES_ERROR,
    ADD_FEATURED_RECENT_ARTICLE_ERROR,
    GET_ALL_URL_TITLES,
    SEARCH_PUBLISHED_ARTICLES,
    SEARCH_PUBLISHED_ARTICLES_ERROR,
} from "../actions/types";

const initialState = {
    articles: [],
    publishedArticles: [],
    articlesUpdated: [],
    articleProducts: [],
    allFeaturedRecentArticles: [],
    featuredRecentArticlesByCategory: [],
    blogs: [],
    ebooks: [],
    research: [],
    caseStudy: [],
    caseStudySearch: [],
    allArticleSearch: [],
    caseStudySearchPageCount: null,
    marketingMaterials: [],
    marketingMaterialsSearch: [],
    marketingMaterialsSearchPageCount: null,
    podcasts: [],
    podcastSearch: [],
    podcastSearchPageCount: null,
    continuingEducation: [],
    continuingEducationSearch: [],
    continuingEducationSearchPageCount: null,
    information: [],
    informationSearch: [],
    informationSearchPageCount: null,
    other: [],
    otherSearch: [],
    otherSearchPageCount: null,
    featuredNewArticles: [],
    selectedArticle: [],
    featuredEbooks: [],
    allUrlTitles: [],
    article: null,
    isLoading: true,
    success: false,
    count: null,
};

export default function (state = initialState, action) {
    const { payload, type } = action;

    switch (type) {
        // case GET_ALL_ADVERTISEMENTS_PAGE:
        case GET_ALL_ARTICLES:
            return {
                ...state,
                articles: payload,
                blogs: payload.filter((item) => item.article_type === "Blogs"),
                ebooks: payload.filter((item) => item.article_type === "eBook"),
                evaluatedProducts: payload.filter(
                    (item) => item.article_type === "Product Evaluation"
                ),
                content: payload.filter(
                    (item) =>
                        item.article_type === "eBook" || item.article_type === "Content"
                ),
                caseStudy: payload.filter((item) => item.article_type === "Case Study"),
                research: payload.filter((item) => item.article_type === "Research"),
                marketingMaterials: payload.filter(
                    (item) => item.article_type === "Marketing Materials"
                ),
                videos: payload.filter((item) => item.article_type === "Videos"),
                isLoading: false,
                success: true,
            };
        case GET_ALL_ARTICLES_TAG:
            return {
                ...state,
                articles: payload,
                blogs: payload.filter((item) => item.article_type === "Blogs"),
                ebooks: payload.filter((item) => item.article_type === "eBook"),
                evaluatedProducts: payload.filter(
                    (item) => item.article_type === "Product Evaluation"
                ),
                content: payload.filter(
                    (item) =>
                        item.article_type === "eBook" || item.article_type === "Content"
                ),
                caseStudy: payload.filter((item) => item.article_type === "Case Study"),
                research: payload.filter((item) => item.article_type === "Research"),
                marketingMaterials: payload.filter(
                    (item) => item.article_type === "Marketing Materials"
                ),
                videos: payload.filter((item) => item.article_type === "Videos"),
                isLoading: false,
                success: true,
            };
        case SEARCH_ALL_ARTICLES_PAGE:
            return {
                ...state,
                allArticleSearch: payload,
                isLoading: false,
                success: true,
            };
        case SEARCH_PUBLISHED_ARTICLES:
            return {
                ...state,
                publishedArticles: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_URL_TITLES:
            return {
                ...state,
                allUrlTitles: payload,
                isLoading: false,
                success: true,
            }
        case GET_ALL_FEATURED_RECENT_ARTICLES:
            return {
                ...state,
                allFeaturedRecentArticles: payload,
                isLoading: false,
                success: true,
            };
        case GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY:
            return {
                ...state,
                featuredRecentArticlesByCategory: payload,
                isLoading: false,
                success: true,
            };
        case GET_EBOOKS:
            return {
                ...state,
                ebooks: payload,
                isLoading: false,
                success: true,
            };
        case GET_ALL_ARTICLES_UPDATED:
            return {
                ...state,
                articlesUpdated: payload
            }
        case GET_ARTICLE:
            return {
                ...state,
                article: payload,
                isLoading: false,
                success: true,
            };
        case GET_ARTICLE_PRODUCTS:
            return {
                ...state,
                articleProducts: payload,
                isLoading: false,
                success: true,
            };
        case GET_FEATURED_ARTICLES:
            return {
                ...state,
                featuredNewArticles: payload,
                isLoading: false,
                success: true,
            };
        case GET_FEATURED_EBOOKS:
            return {
                ...state,
                featuredEbooks: payload,
                isLoading: false,
                success: true,
            };
        case GET_RESEARCH_ARTICLES:
            return {
                ...state,
                research: payload,
                isLoading: false,
                success: true,
            };
        case GET_CASE_STUDY_ARTICLES:
            return {
                ...state,
                caseStudy: payload,
                isLoading: false,
                success: true,
            };
        case GET_MARKETING_MATERIALS_ARTICLES:
            return {
                ...state,
                marketingMaterials: payload,
                isLoading: false,
                success: true,
            };
        case GET_PODCASTS:
        case GET_ALL_PODCASTS:
            return {
                ...state,
                podcasts: payload,
                isLoading: false,
                success: true,
            };
        case GET_CE:
            return {
                ...state,
                continuingEducation: payload,
                isLoading: false,
                success: true,
            };
        case GET_INFORMATION:
            return {
                ...state,
                information: payload,
                isLoading: false,
                success: true,
            };
        case GET_OTHER:
            return {
                ...state,
                other: payload,
                isLoading: false,
                success: true,
            };
        case GET_ARTICLE_COUNT:
        case GET_CASE_COUNT:
        case GET_MARKETING_COUNT:
        case GET_PODCAST_COUNT:
        case ADD_PODCAST:
        case UPDATE_PODCAST:
        case DELETE_PODCAST:
        case GET_CE_COUNT:
        case GET_INFORMATION_COUNT:
        case GET_OTHER_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case SELECT_ARTICLE:
            return {
                ...state,
                selectedArticle: payload,
            };
        case SEARCH_CASE_PAGE:
            return {
                ...state,
                caseStudySearch: payload.rows,
                caseStudySearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_MARKETING_PAGE:
            return {
                ...state,
                marketingMaterialsSearch: payload.rows,
                marketingMaterialsSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_PODCAST_PAGE:
            return {
                ...state,
                podcastSearch: payload.rows,
                podcastSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_CE_PAGE:
            return {
                ...state,
                continuingEducationSearch: payload.rows,
                continuingEducationSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_INFORMATION_PAGE:
            return {
                ...state,
                informationSearch: payload.rows,
                informationSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_OTHER_PAGE:
            return {
                ...state,
                otherSearch: payload.rows,
                otherSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case UNPUBLISH_ARTICLES:
        case UPDATE_ARTICLE:
        case DELETE_ARTICLE:
        case ADD_ARTICLE:
        case ADD_FEATURED_RECENT_ARTICLE:
        case SET_FEATURED_ARTICLE:
        case SET_FEATURED_ARTICLES_FALSE:
        case SET_FEATURED_EBOOK:
        case SET_FEATURED_EBOOKS_FALSE:
        case UPDATE_ARTICLE_PRODUCT_MAP:
        case DELETE_ARTICLE_PRODUCT_ASSOCIATION:
        case DELETE_FEATURED_RECENT_ARTICLES:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case SEARCH_ALL_ARTICLES_PAGE_ERROR:
            return {
                ...state,
                allArticleSearch: payload,
                isLoading: false,
                success: false,
            };
        case SEARCH_PUBLISHED_ARTICLES_ERROR:
            return {
                ...state,
                publishedArticles: [],
                isLoading: false,
                success: false,
            };
        // case GET_ALL_ADVERTISEMENTS_PAGE_ERROR:
        case ALL_ARTICLES_ERROR:
            return {
                ...state,
                articles: payload,
                isLoading: false,
                success: false,
            };
        case GET_ARTICLE_PRODUCTS_ERROR:
            return {
                ...state,
                articleProducts: payload,
                isLoading: false,
                success: false,
            };
        case GET_EBOOKS_ERROR:
            return {
                ...state,
                ebooks: payload,
                isLoading: false,
                success: false,
            };
        case UNPUBLISH_ARTICLES_ERROR:
        case DELETE_ARTICLE_ERROR:
        case UPDATE_ARTICLE_ERROR:
        case ADD_ARTICLE_ERROR:
        case ADD_FEATURED_RECENT_ARTICLE_ERROR:
        case SET_FEATURED_ARTICLE_ERROR:
        case SET_FEATURED_ARTICLES_FALSE_ERROR:
        case SET_FEATURED_EBOOK_ERROR:
        case DELETE_PODCAST_ERROR:
        case UPDATE_PODCAST_ERROR:
        case SET_FEATURED_EBOOKS_FALSE_ERROR:
        case UPDATE_ARTICLE_PRODUCT_MAP_ERROR:
        case DELETE_ARTICLE_PRODUCT_ASSOCIATION_ERROR:
        case DELETE_FEATURED_RECENT_ARTICLES_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        case GET_ARTICLE_ERROR:
            return {
                ...state,
                article: payload,
                isLoading: false,
                success: false,
            };
        case GET_FEATURED_ARTICLES_ERROR:
            return {
                ...state,
                featuredNewArticles: payload,
                isLoading: false,
                success: false,
            };
        case GET_FEATURED_EBOOKS_ERROR:
            return {
                ...state,
                featuredEbooks: payload,
                isLoading: false,
                success: false,
            };
        case GET_ALL_FEATURED_RECENT_ARTICLES_ERROR:
            return {
                ...state,
                allFeaturedRecentArticles: payload,
                isLoading: false,
                success: false,
            };
        case GET_FEATURED_RECENT_ARTICLES_BY_CATEGORY_ERROR:
            return {
                ...state,
                featuredRecentArticlesByCategory: payload,
                isLoading: false,
                success: false,
            };
        case GET_ARTICLE_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false,
            };
        case GET_RESEARCH_ARTICLES_ERROR:
            return {
                ...state,
                research: payload,
                isLoading: false,
                success: false,
            };
        case GET_CASE_STUDY_ARTICLES_ERROR:
            return {
                ...state,
                caseStudy: payload,
                isLoading: false,
                success: false,
            };
        case GET_MARKETING_MATERIALS_ARTICLES_ERROR:
            return {
                ...state,
                marketingMaterials: payload,
                isLoading: false,
                success: false,
            };
        case GET_CE_ERROR:
            return {
                ...state,
                continuingEduction: payload,
                isLoading: false,
                success: false,
            };
        case GET_PODCASTS_ERROR:
        case GET_ALL_PODCASTS_ERROR:
            return {
                ...state,
                podcasts: payload,
                isLoading: false,
                success: false,
            };
        case ADD_PODCAST_ERROR:
        case GET_OTHER_ERROR:
            return {
                ...state,
                other: payload,
                isLoading: false,
                success: false,
            };
        case GET_INFORMATION_ERROR:
            return {
                ...state,
                information: payload,
                isLoading: false,
                success: false,
            };
        case SEARCH_CASE_PAGE_ERROR:
            return {
                ...state,
                caseStudySearch: payload.rows,
                caseStudySearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_PODCAST_PAGE_ERROR:
            return {
                ...state,
                podcastSearch: payload.rows,
                podcastSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_CE_PAGE_ERROR:
            return {
                ...state,
                continuingEducation: payload.rows,
                continuingEducationSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_OTHER_PAGE_ERROR:
            return {
                ...state,
                other: payload.rows,
                otherSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case SEARCH_INFORMATION_PAGE_ERROR:
            return {
                ...state,
                information: payload.rows,
                informationSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        case GET_CASE_COUNT_ERROR:
            return {
                ...state,
                caseCount: payload,
                isLoading: false,
                success: false,
            };
        case GET_OTHER_COUNT_ERROR:
            return {
                ...state,
                caseCount: payload,
                isLoading: false,
                success: false,
            };
        case GET_MARKETING_COUNT_ERROR:
            return {
                ...state,
                marketingCount: payload,
                isLoading: false,
                success: false,
            };
        case GET_PODCAST_COUNT_ERROR:
            return {
                ...state,
                podcastCount: payload,
                isLoading: false,
                success: false,
            };
        case GET_CE_COUNT_ERROR:
            return {
                ...state,
                continuingEducationCount: payload,
                isLoading: false,
                success: false,
            };
        case GET_INFORMATION_COUNT_ERROR:
            return {
                ...state,
                informationCount: payload,
                isLoading: false,
                success: false,
            };
        case SEARCH_MARKETING_PAGE_ERROR:
            return {
                ...state,
                marketingMaterialsSearch: payload.rows,
                marketingMaterialsSearchPageCount: payload.count,
                isLoading: false,
                success: true,
            };
        default:
            return state;
    }
}
