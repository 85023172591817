import React, { useState } from "react";
// import styled, { createGlobalStyle } from "styled-components";
import styled, { createGlobalStyle } from "styled-components";
import Sidebar from "../components/BuyersGuide/SideBar";
import Header from "../components/BuyersGuide/Header";

import { spacing } from '@mui/system';
import {
  CssBaseline,
  Paper as MuiPaper,
  // withWidth,
} from "@mui/material";

// import { isWidthUp } from "@mui/material/withWidth";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const BuyersGuide = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Sidebar
        routes={routes}
        PaperProps={{ style: { width: drawerWidth, position: 'unset' } }}
        open={true}
      />
      <AppContent>
        <Header />
        <MainContent sx={{ padding: 12 }}>
          {/* <MainContent p={isWidthUp("lg", width) ? 12 : 5}> */}
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default (BuyersGuide);
// export default withWidth()(BuyersGuide);
