import React from "react";
import { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import styled from "styled-components";

import {
  CardContent,
  Grid2 as Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { spacing } from '@mui/system';
import Loader from "../../components/Loader";

import {
  getProducts,
  searchPublishedProducts,
  getFeaturedNewProducts,
  setFeaturedNewProduct,
  setFeaturedNewFalse,
} from "../../redux/actions/products";
import { setAlert } from "../../redux/actions/alerts";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
  width: 270px;
`;

const FeaturedProductForm = ({
  getProducts,
  searchPublishedProducts,
  setAlert,
  getFeaturedNewProducts,
  setFeaturedNewProduct,
  setFeaturedNewFalse,
  product: { products, productSearch, isLoading, featuredProductsNewProduct },
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const prodName1 = useRef(null);
  const prodName2 = useRef(null);
  const prodName3 = useRef(null);
  const prodName4 = useRef(null);

  const refDictionary = {
    prodName1,
    prodName2,
    prodName3,
    prodName4,
  }

  const [formValues, setFormValues] = useState({
    prod1: {
      "prod1-select": null,
      "prod1-date": null,
      id: null,
    },
    prod2: {
      "prod2-select": null,
      "prod2-date": null,
      id: null,
    },
    prod3: {
      "prod3-select": null,
      "prod3-date": null,
      id: null,
    },
    prod4: {
      "prod4-select": null,
      "prod4-date": null,
      id: null,
    },
  });

  useEffect(() => {
    let currentForm = {}

    for (let i = 1; i < 5; i++) {
      if (!featuredProductsNewProduct[i - 1]?.new_product_expiration_date) {
        currentForm = {
          ...currentForm,
          [`prod${i}`]: {
            [`prod${i}-select`]: null,
            [`prod${i}-date`]: null,
            id: null,
          }
        }
      } else {
        currentForm = {
          ...currentForm,
          [`prod${i}`]: {
            [`prod${i}-select`]: featuredProductsNewProduct[i - 1].new_product_item_order,
            [`prod${i}-date`]:
              featuredProductsNewProduct[i - 1].new_product_expiration_date,
            id: featuredProductsNewProduct[i - 1].product_id,
            name: featuredProductsNewProduct[i - 1].name,
          }
        }
      }

    }

    setFormValues(prevForm => ({
      ...prevForm,
      ...currentForm
    }))

  }, [featuredProductsNewProduct]);

  const checkIsEmpty = (i) => {
    const currentForm = formValues[`prod${i}`]

    if (!currentForm || (!currentForm[`prod${i}-select`] && !currentForm[`prod${i}-date`] && !currentForm[`id`] && !currentForm[`name`])) {
      return true
    } else {
      return false
    }
  }

  const validateFields = () => {
    for (let i = 1; i < 5; i++) {
      const currentForm = formValues[`prod${i}`]

      if ((!currentForm[`prod${i}-select`] || !currentForm[`prod${i}-date`] || !currentForm[`id`] && !currentForm[`name`]) && !checkIsEmpty(i)) {
        return true
      }
    }

    return false

  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name.substring(0, 5)]: {
        ...formValues[e.target.name.substring(0, 5)],
        [e.target.name]: e.target.value,
      },
    });
  };

  const searchForProducts = (e) => {
    setTimeout(() => {
      if (e.target.value.length < 3) {
        setAlert("Query must be 3 characters or longer", "danger");
      } else {
        searchPublishedProducts(e.target.value);
      }
    }, 1000);
  };

  const handleProduct1Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod1: {
          ...formValues["prod1"],
          id: product.product_id,
        },
      });
    }
  };

  const handleProduct2Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod2: {
          ...formValues["prod2"],
          id: product.product_id,
        },
      });
    }
  };

  const handleProduct3Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod3: {
          ...formValues["prod3"],
          id: product.product_id,
        },
      });
    }
  };

  const handleProduct4Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod4: {
          ...formValues["prod4"],
          id: product.product_id,
        },
      });
    }
  };


  const handleClear = (i) => {
    const clearedValue = {
      [`prod${i}`]: {
        [`prod${i}-select`]: null,
        [`prod${i}-date`]: null,
        id: null,
      }
    }

    const selectClearButton = refDictionary[`prodName${i}`].current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];

    if (selectClearButton) {
      selectClearButton.click()
    }
    setFormValues(prevForm => ({
      ...prevForm,
      ...clearedValue
    }))
  }

  const handleSubmit = async () => {
    if (validateFields()) {
      setAlert("Please fill out all fields below");
    } else {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
      let removedFeatured = await setFeaturedNewFalse();
      const featuredNewProduct = {};
      let productId = null;
      // Loops through the formValue state and fires off requests for each product
      // formValues[product][`${product}-select`]  will dynamically grab the specific properties on the formValue obj
      for (const product in formValues) {
        if (checkIsEmpty(product.replace('prod', ''))) {
          continue
        }

        if (Object.keys(formValues)) {

          featuredNewProduct.order = formValues[product][`${product}-select`];
          featuredNewProduct.expiration_date = formValues[product][`${product}-date`];
          productId = formValues[product].id;
          setFeaturedNewProduct(productId, featuredNewProduct);
        }
      }
    }
  };


  return isLoading ? (
    <Loader />
  ) : (
    <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
      <CardContent>
        <Typography
          variant="h2"
          gutterBottom
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Featured Products - New Product Page
        </Typography>
        <Typography variant='body1'>Notes:</Typography>
        <Typography variant='body1'>If new products are expired, it will fill the remaining slots with "Default Products", which are set in the next tab. </Typography>
        <Typography variant='body1'>Please ensure the product you feature has at least 1 image. Since they are displayed on DPS.</Typography>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 1
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod1-name1"
                ref={prodName1}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct1Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod1["name"] || ''}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  label="order #"
                  defaultValue="pick"
                  value={formValues.prod1["prod1-select"] || ""}
                  name="prod1-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.prod1["prod1-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="prod1-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
              {!checkIsEmpty(1) ?
                <Button onClick={() => handleClear(1)}>Clear</Button>
                : null}
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 2
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod2-name1"
                ref={prodName2}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct2Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod2["name"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.prod2["prod2-select"] || ""}
                  name="prod2-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.prod2["prod2-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="prod2-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
              {!checkIsEmpty(2) ?
                <Button onClick={() => handleClear(2)}>Clear</Button>
                : null}
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 3
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod3-name1"
                ref={prodName3}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct3Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod3["name"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.prod3["prod3-select"] || ""}
                  name="prod3-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.prod3["prod3-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="prod3-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
              {!checkIsEmpty(3) ?
                <Button onClick={() => handleClear(3)}>Clear</Button>
                : null}
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 4
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod4-name1"
                ref={prodName4}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct4Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod4["name"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.prod4["prod4-select"] || ""}
                  name="prod4-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.prod4["prod4-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="prod4-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
              {!checkIsEmpty(4) ?
                <Button onClick={() => handleClear(4)}>Clear</Button>
                : null}

            </form>
          </Paper>
        </Grid>

        <Divider />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={() => handleSubmit()}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, {
  getProducts,
  searchPublishedProducts,
  setAlert,
  getFeaturedNewProducts,
  setFeaturedNewProduct,
  setFeaturedNewFalse,
})(FeaturedProductForm);
