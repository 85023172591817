import React from "react";
import { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import styled from "styled-components";

import {
  CardContent,
  Grid2 as Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { spacing } from '@mui/system';
import Loader from "../Loader";

import {
  getPromotions,
  searchPromotions,
  getPromotionPageFeatured,
  setPromotionPageFeatured,
  setPromotionPageFeaturedFalse,
} from "../../redux/actions/promotions";
import { setAlert } from "../../redux/actions/alerts";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
  width: 270px;
`;

const FeaturedPromotionForm = ({
  getPromotions,
  searchPromotions,
  setAlert,
  getPromotionPageFeatured,
  setPromotionPageFeatured,
  setPromotionPageFeaturedFalse,
  promotion: {
    promotions,
    promotionSearchPage,
    isLoading,
    promotionPageFeatured,
  },
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const promoName1 = useRef();
  const promoName2 = useRef();
  const promoName3 = useRef();
  const promoName4 = useRef();
  const promoName5 = useRef();

  const [formValues, setFormValues] = useState({
    promo1: {
      "promo1-select": null,
      "promo1-date": null,
      id: null,
    },
    promo2: {
      "promo2-select": null,
      "promo2-date": null,
      id: null,
    },
    promo3: {
      "promo3-select": null,
      "promo3-date": null,
      id: null,
    },
    promo4: {
      "promo4-select": null,
      "promo4-date": null,
      id: null,
    },
    promo5: {
      "promo5-select": null,
      "promo5-date": null,
      id: null,
    },
  });

  function generateObj(elem) {
    return {
      "promo1-select": elem ? elem.promotion_page_item_order : '',
      "promo1-date": elem ? elem.promotion_page_expiration_date : '',
      id: elem ? elem.promotion_id : '',
      title: elem ? elem.title : '',
    }
  }

  useEffect(() => {
    if (promotionPageFeatured.length > 0) {
      let obj = formValues
      promotionPageFeatured.forEach(e => {
        if (e.promotion_page_item_order === 1) {
          obj['promo1'] = generateObj(e)
        }
        else if (e.promotion_page_item_order === 2) {
          obj['promo2'] = generateObj(e)
        }
        else if (e.promotion_page_item_order === 3) {
          obj['promo3'] = generateObj(e)
        }
        else if (e.promotion_page_item_order === 4) {
          obj['promo4'] = generateObj(e)
        }
        else if (e.promotion_page_item_order === 5) {
          obj['promo5'] = generateObj(e)
        }
      });



      // if (!obj['promo1']) {
      //   obj['promo1'] = generateObj()
      // }
      // if (!obj['promo2']) {
      //   obj['promo2'] = generateObj()
      // }
      // if (!obj['promo3']) {
      //   obj['promo3'] = generateObj()
      // }
      // if (!obj['promo4']) {
      //   obj['promo4'] = generateObj()
      // }
      // if (!obj['promo5']) {
      //   obj['promo5'] = generateObj()
      // }

      // console.log(obj)

      setFormValues({ ...obj })
    }
  }, [promotionPageFeatured]);

  const validateFields = () => {
    let validState = false
    if (formValues.promo1["promo1-select"] || formValues.promo1["promo1-date"] || formValues.promo1["id"]) {
      if (formValues.promo1["promo1-select"] && formValues.promo1["promo1-date"] && formValues.promo1["id"]) {
        validState = true
      }
    }

    if (formValues.promo2["promo2-select"] || formValues.promo2["promo2-date"] || formValues.promo2["id"]) {
      if (formValues.promo2["promo2-select"] && formValues.promo2["promo2-date"] && formValues.promo2["id"]) {
        validState = true
      }
    }

    if (formValues.promo3["promo3-select"] || formValues.promo3["promo3-date"] || formValues.promo3["id"]) {
      if (formValues.promo3["promo3-select"] && formValues.promo3["promo3-date"] && formValues.promo3["id"]) {
        validState = true
      }
    }

    if (formValues.promo4["promo4-select"] || formValues.promo4["promo4-date"] || formValues.promo4["id"]) {
      if (formValues.promo4["promo4-select"] && formValues.promo4["promo4-date"] && formValues.promo4["id"]) {
        validState = true
      }
    }

    if (formValues.promo5["promo5-select"] || formValues.promo5["promo5-date"] || formValues.promo5["id"]) {
      if (formValues.promo5["promo5-select"] && formValues.promo5["promo5-date"] && formValues.promo5["id"]) {
        validState = true
      }
    }

    if (!validState) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name.substring(0, 6)]: {
        ...formValues[e.target.name.substring(0, 6)],
        [e.target.name]: e.target.value,
      },
    });
  };

  const searchForPromotions = (e) => {
    setTimeout(() => {
      if (e.target.value.length < 3) {
        setAlert("Query must be 3 characters or longer", "danger");
      } else {
        searchPromotions(e.target.value);
      }
    }, 1000);
  };

  const handlePromotion1Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo1: {
          ...formValues["promo1"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion2Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo2: {
          ...formValues["promo2"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion3Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo3: {
          ...formValues["promo3"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion4Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo4: {
          ...formValues["promo4"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion5Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo5: {
          ...formValues["promo5"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      setAlert("Please fill out all fields below");
    } else {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
      let removedFeatured = await setPromotionPageFeaturedFalse();
      const featuredNewPromotion = {};
      let promotionId = null;
      // Loops through the formValue state and fires off requests for each promotion
      // formValues[promotion][`${promotion}-select`]  will dynamically grab the specific properties on the formValue obj
      for (const promotion in formValues) {
        if (Object.keys(formValues)) {
          featuredNewPromotion.order =
            formValues[promotion][`${promotion}-select`];
          featuredNewPromotion.expiration_date =
            formValues[promotion][`${promotion}-date`];
          promotionId = formValues[promotion].id;
          setPromotionPageFeatured(promotionId, featuredNewPromotion);
        }
      }
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
      <CardContent>
        <Typography
          variant="h2"
          gutterBottom
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Featured Promotions - Featured Promotions Page
        </Typography>
        <div>Note: If a promotion is expired, it will fill the remaining slots with "Default Promotions", which are set in the next tab. </div>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 1
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo1-name1"
                ref={promoName1}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion1Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo1["title"]}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  label="order #"
                  defaultValue="pick"
                  value={formValues.promo1["promo1-select"] || ""}
                  name="promo1-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.promo1["promo1-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="promo1-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 2
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod2-name1"
                ref={promoName2}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion2Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo2["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.promo2["promo2-select"] || ""}
                  name="promo2-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.promo2["promo2-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="promo2-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 3
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo3-name1"
                ref={promoName3}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion3Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo3["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.promo3["promo3-select"] || ""}
                  name="promo3-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.promo3["promo3-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="promo3-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 4
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo4-name1"
                ref={promoName4}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion4Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo4["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.promo4["promo4-select"] || ""}
                  name="promo4-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.promo4["promo4-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="promo4-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 5
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo5-name1"
                ref={promoName5}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search For Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion5Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo5["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
              <FormControl>
                <InputLabel
                  variant='standard'
                  style={{
                    fontSize: "12px",
                    marginLeft: "20px",
                  }}
                >
                  Order #
                </InputLabel>
                <Select
                  variant='standard'
                  m={2}
                  labelId="order-select"
                  defaultValue=" "
                  value={formValues.promo5["promo5-select"] || ""}
                  name="promo5-select"
                  onChange={handleChange}
                  style={{
                    marginTop: "24px",
                    marginRight: "30px",
                    marginLeft: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                id="date"
                label="Expiration Date"
                type="date"
                value={formValues.promo5["promo5-date"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                name="promo5-date"
                onChange={handleChange}
                style={{ marginTop: "8px", marginRight: "20px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Divider />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={() => handleSubmit()}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  promotion: state.promotion,
});

export default connect(mapStateToProps, {
  getPromotions,
  searchPromotions,
  setAlert,
  getPromotionPageFeatured,
  setPromotionPageFeatured,
  setPromotionPageFeaturedFalse,
})(FeaturedPromotionForm);
