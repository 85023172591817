import {
    DL_ADD_CATEGORY,
    DL_ADD_CATEGORY_ERROR,
    DL_GET_ALL_CATEGORIES,
    DL_GET_ALL_CATEGORIES_ERROR,
    DL_GET_PUBLISHED_CATEGORIES,
    DL_GET_PUBLISHED_CATEGORIES_ERROR,
    DL_GET_CATEGORIES_PAGE,
    DL_GET_CATEGORIES_PAGE_ERROR,
    DL_SEARCH_CATEGORIES_PAGE,
    DL_SEARCH_CATEGORIES_PAGE_ERROR,
    SELECT_CATEGORY_DL,
    DL_GET_ALL_CATEGORY_COUNT,
    DL_GET_ALL_CATEGORY_COUNT_ERROR,
    DL_UPDATE_CATEGORY,
    DL_UPDATE_CATEGORY_ERROR,
    DL_DELETE_CATEGORY,
    DL_DELETE_CATEGORY_ERROR,
    DL_ADD_FEATURED_CATEGORY_CONTENT,
    DL_ADD_FEATURED_CATEGORY_CONTENT_ERROR,
    DL_GET_ALL_FEATURED_CATEGORY_CONTENT,
    DL_GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR,
    DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE,
    DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR
} from "../actions/types";

const initialState = {
    count: null,
    categories: [],
    publishedCategories: [],
    categoriesSearchPage: [],
    featuredCategoryContentDL: [],
    selectedCategory: null,
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_ALL_CATEGORIES:
        case DL_GET_CATEGORIES_PAGE:
            return {
                ...state,
                categories: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_PUBLISHED_CATEGORIES:
            return {
                ...state,
                publishedCategories: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_FEATURED_CATEGORY_CONTENT:
            return {
                ...state,
                featuredCategoryContentDL: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_FEATURED_CATEGORY_CONTENT_ERROR:
            return {
                ...state,
                featuredCategoryContentDL: payload,
                isLoading: false,
                success: false,
            };
        case DL_GET_ALL_CATEGORY_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true
            }
        case DL_GET_ALL_CATEGORY_COUNT_ERROR:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: false
            }
        case SELECT_CATEGORY_DL:
            return {
                ...state,
                selectedCategory: payload,
            };
        case DL_SEARCH_CATEGORIES_PAGE:
            return {
                ...state,
                categoriesSearchPage: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_CATEGORIES_PAGE_ERROR:
            return {
                ...state,
                categoriesSearchPage: payload,
                isLoading: false,
                success: false,
            };
        case DL_GET_ALL_CATEGORIES_ERROR:
        case DL_GET_CATEGORIES_PAGE_ERROR:
            return {
                ...state,
                categories: payload,
                isLoading: false,
                success: false,
            };
        case DL_GET_PUBLISHED_CATEGORIES_ERROR:
        case DL_ADD_CATEGORY:
        case DL_ADD_FEATURED_CATEGORY_CONTENT:
        case DL_UPDATE_CATEGORY:
        case DL_DELETE_CATEGORY:
        case DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE:
            return {
                ...state,
                isLoading: false,
                success: true,
            };
        case DL_ADD_CATEGORY_ERROR:
        case DL_ADD_FEATURED_CATEGORY_CONTENT_ERROR:
        case DL_UPDATE_CATEGORY_ERROR:
        case DL_DELETE_CATEGORY_ERROR:
        case DL_DELETE_FEATURED_CATEGORY_CONTENT_BY_TYPE_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
