import {
    DL_GET_COMPETITOR_EXCLUSIONS,
    DL_GET_COMPETITOR_EXCLUSIONS_ERROR,
    DL_GET_COMPETITOR_EXCLUSIONS_COUNT,
    DL_GET_COMPETITOR_EXCLUSIONS_COUNT_ERROR,
    DL_SEARCH_COMPETITOR_EXCLUSIONS,
    DL_SEARCH_COMPETITOR_EXCLUSIONS_ERROR,
    DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT,
    DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT_ERROR,
    DL_ADD_COMPETITOR_EXCLUSIONS,
    DL_ADD_COMPETITOR_EXCLUSIONS_ERROR,
    DL_UPDATE_COMPETITOR_EXCLUSIONS,
    DL_UPDATE_COMPETITOR_EXCLUSIONS_ERROR,
    DL_DELETE_COMPETITOR_EXCLUSIONS,
    DL_DELETE_COMPETITOR_EXCLUSIONS_ERROR,
    DL_SELECT_COMPETITOR_EXCLUSION,
    DL_GET_COMPETITOR_EXCLUDED_IDS,
    DL_GET_COMPETITOR_EXCLUDED_IDS_ERROR,
    DL_GET_ALL_COMPETITOR_EXCLUDED_IDS,
    DL_GET_ALL_COMPETITOR_EXCLUDED_IDS_ERROR,
} from "../actions/types";

const initialState = {
    count: null,
    searchCount: null,
    exclusions: [],
    searchExclusions: [],
    selectedExclusion: null,
    excludedIds: [],
    excludedType: null,
    excludedIdObject: {
        Courses: [],
        Events: [],
        'Take 5 Videos': [],
        // 'DPS EBooks': [],
        // 'DPS Evaluations': [],
        // 'DPS Products': [],
    },
    isLoading: true,
    success: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DL_GET_COMPETITOR_EXCLUSIONS:
            return {
                ...state,
                exclusions: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_COMPETITOR_EXCLUSIONS_COUNT:
            return {
                ...state,
                count: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_COMPETITOR_EXCLUSIONS:
            return {
                ...state,
                searchExclusions: payload,
                isLoading: false,
                success: true,
            };
        case DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT:
            return {
                ...state,
                searchCount: payload,
                isLoading: false,
                success: true,
            };
        case DL_GET_COMPETITOR_EXCLUDED_IDS:
            return {
                ...state,
                excludedIds: payload.ids,
                excludedType: payload.contentType,
                isLoading: false,
                success: true,
            };
        case DL_GET_ALL_COMPETITOR_EXCLUDED_IDS:
            return {
                ...state,
                excludedIdObject: payload,
                isLoading: false,
                success: true,
            };
        case DL_SELECT_COMPETITOR_EXCLUSION:
            return {
                ...state,
                selectedExclusion: payload,
            };

        case DL_ADD_COMPETITOR_EXCLUSIONS:
        case DL_UPDATE_COMPETITOR_EXCLUSIONS:
        case DL_DELETE_COMPETITOR_EXCLUSIONS:
            return {
                ...state,
                isLoading: false,
                success: true,
            };

        case DL_GET_COMPETITOR_EXCLUSIONS_ERROR:

            return {
                ...state,
                exclusions: [],
                isLoading: false,
                success: false,
            };
        case DL_GET_COMPETITOR_EXCLUSIONS_COUNT_ERROR:

            return {
                ...state,
                count: null,
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_COMPETITOR_EXCLUSIONS_ERROR:

            return {
                ...state,
                searchExclusions: [],
                isLoading: false,
                success: false,
            };
        case DL_SEARCH_COMPETITOR_EXCLUSIONS_COUNT_ERROR:

            return {
                ...state,
                searchCount: null,
                isLoading: false,
                success: false,
            };
        case DL_GET_COMPETITOR_EXCLUDED_IDS_ERROR:

            return {
                ...state,
                excludedIds: [],
                excludedType: null,
                isLoading: false,
                success: false,
            };
        case DL_GET_ALL_COMPETITOR_EXCLUDED_IDS_ERROR:

            return {
                ...state,
                excludedIdObject: initialState.excludedIdObject,
                isLoading: false,
                success: false,
            };

        case DL_ADD_COMPETITOR_EXCLUSIONS_ERROR:
        case DL_UPDATE_COMPETITOR_EXCLUSIONS_ERROR:
        case DL_DELETE_COMPETITOR_EXCLUSIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                success: false,
            };
        default:
            return state;
    }
}
