import React from "react";
import { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import styled from "styled-components";

import {
  CardContent,
  Grid2 as Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { spacing } from '@mui/system';
import Loader from "../Loader";

import {
  getPromotions,
  searchPromotions,
  getPromotionPageDefault,
  setPromotionsPageDefault,
  setPromotionsPageDefaultFalse,
} from "../../redux/actions/promotions";
import { setAlert } from "../../redux/actions/alerts";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
  width: 270px;
`;

const DefaultPromotionForm = ({
  getPromotions,
  searchPromotions,
  setAlert,
  getPromotionPageDefault,
  setPromotionsPageDefault,
  setPromotionsPageDefaultFalse,
  promotion: {
    promotions,
    promotionSearchPage,
    isLoading,
    promotionPageDefault,
  },
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const promoName1 = useRef();
  const promoName2 = useRef();
  const promoName3 = useRef();
  const promoName4 = useRef();
  const promoName5 = useRef();

  const [formValues, setFormValues] = useState({
    promo1: {
      id: null,
    },
    promo2: {
      id: null,
    },
    promo3: {
      id: null,
    },
    promo4: {
      id: null,
    },
    promo5: {
      id: null,
      title: null,
    },
  });

  useEffect(() => {
    promotionPageDefault.length > 4 &&
      setFormValues({
        promo1: {
          id: promotionPageDefault[0].promotion_id,
          title: promotionPageDefault[0].title,
        },
        promo2: {
          id: promotionPageDefault[1].promotion_id,
          title: promotionPageDefault[1].title,
        },
        promo3: {
          id: promotionPageDefault[2].promotion_id,
          title: promotionPageDefault[2].title,
        },
        promo4: {
          id: promotionPageDefault[3].promotion_id,
          title: promotionPageDefault[3].title,
        },
        promo5: {
          id: promotionPageDefault[4].promotion_id,
          title: promotionPageDefault[4].title,
        },
      });
  }, [promotionPageDefault]);

  const validateFields = () => {
    if (
      !(
        formValues.promo1["id"] ||
        formValues.promo2["id"] ||
        formValues.promo3["id"] ||
        formValues.promo4["id"] ||
        formValues.promo5["id"]
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name.substring(0, 6)]: {
        ...formValues[e.target.name.substring(0, 6)],
        [e.target.name]: e.target.value,
      },
    });
  };

  const searchForPromotions = (e) => {
    setTimeout(() => {
      if (e.target.value.length < 3) {
        setAlert("Query must be 3 characters or longer", "danger");
      } else {
        searchPromotions(e.target.value);
      }
    }, 1000);
  };

  const handlePromotion1Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo1: {
          ...formValues["promo1"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion2Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo2: {
          ...formValues["promo2"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion3Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo3: {
          ...formValues["promo3"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion4Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo4: {
          ...formValues["promo4"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handlePromotion5Select = (e, promotion) => {
    if (promotion) {
      setFormValues({
        ...formValues,
        promo5: {
          ...formValues["promo5"],
          id: promotion.promotion_id,
        },
      });
    }
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      setAlert("Please fill out all fields below");
    } else {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
      await setPromotionsPageDefaultFalse();
      let promotionId;
      // Loops through the formValue state and fires off requests for each promotion
      // formValues[promotion][`${promotion}-select`]  will dynamically grab the specific properties on the formValue obj
      for (const promotion in formValues) {
        if (Object.keys(formValues)) {
          promotionId = formValues[promotion].id;
          setPromotionsPageDefault(promotionId);
        }
      }
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
      <CardContent>
        <Typography
          variant="h2"
          gutterBottom
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Default Promotions - Featured Promotion Page
        </Typography>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 1
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo1-name1"
                ref={promoName1}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion1Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo1["title"]}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 2
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo2-name1"
                ref={promoName2}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion2Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo2["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 3
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo3-name1"
                ref={promoName3}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, promo) => handlePromotion3Select(e, promo)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo3["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 4
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promo4-name1"
                ref={promoName4}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion4Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo4["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Promotion 5
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="promot5-name1"
                ref={promoName5}
                options={promotionSearchPage ? promotionSearchPage : []}
                getOptionLabel={(option) => option.title}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForPromotions(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Promotion"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handlePromotion5Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Default Promotion Title"
                type="text"
                value={formValues.promo5["title"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Divider />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={() => handleSubmit()}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  promotion: state.promotion,
});

export default connect(mapStateToProps, {
  getPromotions,
  searchPromotions,
  setAlert,
  getPromotionPageDefault,
  setPromotionsPageDefault,
  setPromotionsPageDefaultFalse,
})(DefaultPromotionForm);
