import {
  GET_ALL_ADVERTISEMENTS,
  GET_ALL_ADVERTISEMENTS_PAGE,
  ALL_ADVERTISEMENTS_ERROR,
  GET_ADVERTISEMENT,
  GET_ADVERTISEMENT_MANUFACTURER,
  GET_ADVERTISEMENT_ERROR,
  GET_ADVERTISEMENT_COUNT,
  GET_ADVERTISEMENT_COUNT_ERROR,
  ADD_ADVERTISEMENT,
  SELECT_ADVERTISEMENT,
  ADD_ADVERTISEMENT_ERROR,
  UPDATE_ADVERTISEMENT,
  DELETE_ADVERTISEMENT,
  UPDATE_ADVERTISEMENT_ERROR,
  DELETE_ADVERTISEMENT_ERROR,
  GET_ALL_ADVERTISEMENTS_PAGE_ERROR,
  GET_ADVERTISEMENT_MANUFACTURER_ERROR,
  SEARCH_ADVERTISEMENTS_PAGE,
  SEARCH_ADVERTISEMENTS_PAGE_ERROR,
  GET_ADVERTISEMENTS_CONTENT,
  GET_ADVERTISEMENTS_CONTENT_ERROR,
} from "../actions/types";

const initialState = {
  advertisements: [],
  advertisement: null,
  advertisementManufacturer: [],
  advertisementSearchPage: [],
  advertisementSearchPageCount: null,
  selectedAdvertisement: null,
  count: null,
  advertisementContent: null,
  isLoading: true,
  success: false,
};

export default function (state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_ADVERTISEMENTS_PAGE:
    case GET_ALL_ADVERTISEMENTS:
      return {
        ...state,
        advertisements: payload,
        isLoading: false,
        success: true,
      };
    case GET_ADVERTISEMENT:
      return {
        ...state,
        advertisement: payload,
        isLoading: false,
        success: true,
      };
    case SEARCH_ADVERTISEMENTS_PAGE:
      return {
        ...state,
        advertisementSearchPage: payload.rows,
        advertisementSearchPageCount: payload.count,
        isLoading: false,
        success: true,
      };
    case GET_ADVERTISEMENT_COUNT:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: true,
      };
    case GET_ADVERTISEMENT_MANUFACTURER:
      return {
        ...state,
        advertisementManufacturer: payload,
        isLoading: false,
        success: true,
      };
    case GET_ADVERTISEMENTS_CONTENT:
      return {
        ...state,
        advertisementContent: payload,
        isLoading: false,
        success: true,
      };
    case UPDATE_ADVERTISEMENT:
    case DELETE_ADVERTISEMENT:
      return {
        ...state,
        isLoading: false,
        success: true,
      };

    case SELECT_ADVERTISEMENT:
      return {
        ...state,
        selectedAdvertisement: payload,
        isLoading: false,
        success: true,
      };
    case GET_ALL_ADVERTISEMENTS_PAGE_ERROR:
    case ALL_ADVERTISEMENTS_ERROR:
      return {
        ...state,
        advertisements: payload,
        isLoading: false,
        success: false,
      };
    case DELETE_ADVERTISEMENT_ERROR:
    case UPDATE_ADVERTISEMENT_ERROR:
    case ADD_ADVERTISEMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case GET_ADVERTISEMENT_ERROR:
      return {
        ...state,
        advertisement: payload,
        isLoading: false,
        success: false,
      };
    case SEARCH_ADVERTISEMENTS_PAGE_ERROR:
      return {
        ...state,
        advertisementSearchPage: payload.rows,
        advertisementSearchPageCount: payload.count,
        isLoading: false,
        success: false,
      };
    case GET_ADVERTISEMENT_COUNT_ERROR:
      return {
        ...state,
        count: payload,
        isLoading: false,
        success: false,
      };
    case GET_ADVERTISEMENT_MANUFACTURER_ERROR:
      return {
        ...state,
        advertisementManufacturer: payload,
        isLoading: false,
        success: true,
      };
    case GET_ADVERTISEMENTS_CONTENT_ERROR:
      return {
        ...state,
        advertisementContent: null,
        isLoading: false,
        success: true,
      };
    default:
      return state;
  }
}
