import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { darken } from "polished";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { spacing } from '@mui/system';

import {
  Badge,
  Box as MuiBox,
  Chip,
  Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
} from "@mui/material";

import { specificBuyersGuideData } from '../../redux/actions/buyersGuide';
import { buyersGuideSidebarRoutes as routesBuyersGuide } from "../../routes/index";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Category = styled(ListItemButton)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(8)};
  padding-right: ${(props) => props.theme.spacing(7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const SidebarCategory = ({
  name,
  ...rest
}) => {
  return (
    <Category {...rest} >
      <CategoryText>{name}</CategoryText>
    </Category>
  );
};


const Sidebar = ({ buyersGuide: { specificBuyersGuideData }, classes, staticContext, location, ...rest }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (Object.keys(specificBuyersGuideData).length > 0) {

  //   }
  // }, [specificBuyersGuideData])

  delete rest.dispatch
  return Object.keys(specificBuyersGuideData).length > 0 && (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/buyers-guide" >
        <Box>{'Buyers Guide'}</Box>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routesBuyersGuide.map((elem, index) => {
              return (
                <React.Fragment key={`route-${index}`}>
                  <SidebarCategory
                    name={elem.id}
                    to={!disableCaseCheck(index, specificBuyersGuideData.curr_stage) ? elem.path : '#'}
                    disabled={!disableCaseCheck(index, specificBuyersGuideData.curr_stage) ? false : true}
                    component={NavLink}
                  />
                </React.Fragment>
              )
            })}
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

const mapStateToProps = state => ({
  buyersGuide: state.buyersGuide
});

export default connect(
  mapStateToProps
)(Sidebar);

const disableCaseCheck = (index, curr_stage) => {
  let disabled = false

  if (curr_stage < 5) {
    if (index >= curr_stage) {
      disabled = true
    }
  }
  else if (curr_stage >= 5) {
    const disabledAt5 = [0, 1, 3]

    if (disabledAt5.indexOf(index) !== -1) {
      disabled = true
    }

  }

  return disabled
}