//alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//advertisements
export const DXP_GET_ALL_ADVERTISEMENTS = "DXP_GET_ALL_ADVERTISEMENTS";
export const DXP_GET_ALL_ADVERTISEMENTS_ERROR = "DXP_GET_ALL_ADVERTISEMENTS_ERROR";
export const DXP_GET_ADVERTISEMENTS_PAGE = "DXP_GET_ADVERTISEMENTS_PAGE";
export const DXP_GET_ADVERTISEMENTS_PAGE_ERROR = "DXP_GET_ADVERTISEMENTS_PAGE_ERROR";
export const DXP_SEARCH_ADVERTISEMENTS_PAGE = "DXP_SEARCH_ADVERTISEMENTS_PAGE";
export const DXP_SEARCH_ADVERTISEMENTS_PAGE_ERROR = "DXP_SEARCH_ADVERTISEMENTS_PAGE_ERROR";
export const DXP_ADD_ADVERTISEMENT = "DXP_ADD_ADVERTISEMENT";
export const DXP_ADD_ADVERTISEMENT_ERROR = "DXP_ADD_ADVERTISEMENT_ERROR";
export const DXP_SELECT_ADVERTISEMENT = "DXP_SELECT_ADVERTISEMENT";
export const DXP_GET_ALL_ADVERTISEMENT_COUNT = "DXP_GET_ALL_ADVERTISEMENT_COUNT";
export const DXP_GET_ALL_ADVERTISEMENT_COUNT_ERROR = "DXP_GET_ALL_ADVERTISEMENT_COUNT_ERROR";
export const DXP_UPDATE_ADVERTISEMENT = "DXP_UPDATE_ADVERTISEMENT";
export const DXP_UPDATE_ADVERTISEMENT_ERROR = "DXP_UPDATE_ADVERTISEMENT_ERROR";
export const DXP_DELETE_ADVERTISEMENT = "DXP_DELETE_ADVERTISEMENT";
export const DXP_DELETE_ADVERTISEMENT_ERROR = "DXP_DELETE_ADVERTISEMENT_ERROR";
export const DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME = "DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME";
export const DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR = "DXP_SEARCH_ADVERTISEMENTS_PAGE_BY_PAGE_NAME_ERROR";
export const DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS = "DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS";
export const DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR = "DXP_GET_ALL_MANUFACTURER_ADVERTISEMENTS_ERROR";
export const DXP_GET_MANUFACTURER_ADVERTISEMENTS = "DXP_GET_MANUFACTURER_ADVERTISEMENTS";
export const DXP_GET_MANUFACTURER_ADVERTISEMENTS_ERROR = "DXP_GET_MANUFACTURER_ADVERTISEMENTS_ERROR";
export const DXP_ADD_ADVERTISEMENTS_BULK = "DXP_ADD_ADVERTISEMENTS_BULK";
export const DXP_ADD_ADVERTISEMENTS_BULK_ERROR = "DXP_ADD_ADVERTISEMENTS_BULK_ERROR";

//categories
export const DXP_GET_CATEGORY = "DXP_GET_CATEGORY";
export const DXP_GET_CATEGORY_ERROR = "DXP_GET_CATEGORYS_ERROR";
export const DXP_GET_SUB_CATEGORY = "DXP_GET_SUB_CATEGORY";
export const DXP_GET_SUB_CATEGORY_ERROR = "DXP_GET_SUB_CATEGORYS_ERROR";
export const DXP_GET_ALL_CATEGORIES = "DXP_GET_ALL_CATEGORIES";
export const DXP_GET_ALL_CATEGORIES_ERROR = "DXP_GET_ALL_CATEGORIES_ERROR";

//manufacturers
export const DXP_GET_ALL_MANUFACTURERS = "DXP_GET_ALL_MANUFACTURERS";
export const DXP_GET_ALL_MANUFACTURERS_ERROR = "DXP_GET_ALL_MANUFACTURERS_ERROR";
export const DXP_GET_MANUFACTURER = "DXP_GET_MANUFACTURER";
export const DXP_GET_MANUFACTURER_ERROR = "DXP_GET_MANUFACTURER_ERROR";
export const DXP_UPDATE_MANUFACTURER = "DXP_UPDATE_MANUFACTURER";
export const DXP_UPDATE_MANUFACTURER_ERROR = "DXP_UPDATE_MANUFACTURER_ERROR";
// export const ADD_MANUFACTURER = "ADD_MANUFACTURER";
// export const ADD_MANUFACTURER_ERROR = "ADD_MANUFACTURER_ERROR";

//subCategories
export const DXP_GET_SUB_CATEGORIES = "DXP_GET_SUB_CATEGORIES";
export const DXP_GET_SUB_CATEGORIES_ERROR = "DXP_GET_SUB_CATEGORIES_ERROR";
export const DXP_GET_ALL_SUB_CATEGORIES = "DXP_GET_ALL_SUB_CATEGORIES";
export const DXP_GET_ALL_SUB_CATEGORIES_ERROR = "DXP_GET_ALL_SUB_CATEGORIES_ERROR";
export const DXP_ADD_CATEGORY = "DXP_ADD_CATEGORY";
export const DXP_ADD_CATEGORY_ERROR = "DXP_ADD_CATEGORY_ERROR";
export const DXP_ADD_SUB_CATEGORY = "DXP_ADD_SUB_CATEGORY";
export const DXP_ADD_SUB_CATEGORY_ERROR = "DXP_ADD_SUB_CATEGORY_ERROR";
export const DXP_DELETE_CATEGORY = "DXP_DELETE_CATEGORY";
export const DXP_DELETE_CATEGORY_ERROR = "DXP_DELETE_CATEGORY_ERROR";
export const DXP_DELETE_SUB_CATEGORY = "DXP_DELETE_SUB_CATEGORY";
export const DXP_DELETE_SUB_CATEGORY_ERROR = "DXP_DELETE_SUB_CATEGORY_ERROR";
export const DXP_UPDATE_SUB_CATEGORY = "DXP_UPDATE_SUB_CATEGORY";
export const DXP_UPDATE_SUB_CATEGORY_ERROR = "DXP_UPDATE_SUB_CATEGORY_ERROR";
export const DXP_UPDATE_CATEGORY = "DXP_UPDATE_CATEGORY";
export const DXP_UPDATE_CATEGORY_ERROR = "DXP_UPDATE_CATEGORY_ERROR";
export const DXP_GET_NULL_SUB_CATEGORIES = "DXP_GET_NULL_SUB_CATEGORIES";
export const DXP_GET_NULL_SUB_CATEGORIES_ERROR = "DXP_GET_NULL_SUB_CATEGORIES_ERROR";

// related content
export const DXP_GET_RELATED_CONTENT = "DXP_GET_RELATED_CONTENT";
export const DXP_GET_RELATED_CONTENT_ERROR = "DXP_GET_RELATED_CONTENT_ERROR";
export const DXP_SET_SELECTED_TYPE = "DXP_SET_SELECTED_TYPE";

//courses
export const DXP_ADD_COURSE = "DXP_ADD_COURSE";
export const DXP_ADD_COURSE_ERROR = "DXP_ADD_COURSE_ERROR";
export const DXP_GET_ALL_COURSES = "DXP_GET_ALL_COURSES";
export const DXP_GET_ALL_COURSES_ERROR = "DXP_GET_ALL_COURSES_ERROR";
export const DXP_GET_ALL_COURSES_BY_CATEGORY = "DXP_GET_ALL_COURSES_BY_CATEGORY";
export const DXP_GET_ALL_COURSES_BY_CATEGORY_ERROR = "DXP_GET_ALL_COURSES_BY_CATEGORY_ERROR";
export const DXP_GET_ALL_COURSE_PARTS = "DXP_GET_ALL_COURSE_PARTS";
export const DXP_GET_ALL_COURSE_PARTS_ERROR = "DXP_GET_ALL_COURSE_PARTS_ERROR";
export const DXP_GET_COURSES_PAGE = "DXP_GET_COURSES_PAGE";
export const DXP_GET_COURSES_PAGE_ERROR = "DXP_GET_COURSES_PAGE_ERROR";
export const DXP_SEARCH_COURSES_PAGE = "DXP_SEARCH_COURSES_PAGE";
export const DXP_SEARCH_COURSES_PAGE_ERROR = "DXP_SEARCH_COURSES_PAGE_ERROR";
export const DXP_SELECT_COURSE = "DXP_SELECT_COURSE";
export const DXP_GET_ALL_COURSE_COUNT = "DXP_GET_ALL_COURSE_COUNT";
export const DXP_GET_ALL_COURSE_COUNT_ERROR = "DXP_GET_ALL_COURSE_COUNT_ERROR";
export const DXP_UPDATE_COURSE = "DXP_UPDATE_COURSE";
export const DXP_UPDATE_COURSE_ERROR = "DXP_UPDATE_COURSE_ERROR";
export const DXP_DELETE_COURSE = "DXP_DELETE_COURSE";
export const DXP_DELETE_COURSE_ERROR = "DXP_DELETE_COURSE_ERROR";
export const DXP_SEARCH_COURSES_COUNT = "DXP_SEARCH_COURSES_COUNT";
export const DXP_SEARCH_COURSES_COUNT_ERROR = "DXP_SEARCH_COURSES_COUNT_ERROR";
export const DXP_GET_COMPLETED_COURSE_DATA = "DXP_GET_COMPLETED_COURSE_DATA";
export const DXP_GET_COMPLETED_COURSE_DATA_ERROR = "DXP_GET_COMPLETED_COURSE_DATA_ERROR";
export const DXP_GET_ALL_COURSE_CREDIT_TYPES = "DXP_GET_ALL_COURSE_CREDIT_TYPES";
export const DXP_GET_ALL_COURSE_CREDIT_TYPES_ERROR = "DXP_GET_ALL_COURSE_CREDIT_TYPES_ERROR";
export const DXP_GET_COURSE_OPTIONS = "DXP_GET_COURSE_OPTIONS";
export const DXP_GET_COURSE_OPTIONS_ERROR = "DXP_GET_COURSE_OPTIONS_ERROR";




//videos
export const DXP_ADD_VIDEO = "DXP_ADD_VIDEO";
export const DXP_ADD_VIDEO_ERROR = "DXP_ADD_VIDEO_ERROR";
export const DXP_GET_ALL_VIDEOS = "DXP_GET_ALL_VIDEOS";
export const DXP_GET_ALL_VIDEOS_ERROR = "DXP_GET_ALL_VIDEOS_ERROR";
export const DXP_GET_VIDEOS_PAGE = "DXP_GET_VIDEOS_PAGE";
export const DXP_GET_VIDEOS_PAGE_ERROR = "DXP_GET_VIDEOS_PAGE_ERROR";
export const DXP_SEARCH_VIDEOS_PAGE = "DXP_SEARCH_VIDEOS_PAGE";
export const DXP_SEARCH_VIDEOS_PAGE_ERROR = "DXP_SEARCH_VIDEOS_PAGE_ERROR";
export const DXP_SELECT_VIDEO = "DXP_SELECT_VIDEO";
export const DXP_GET_ALL_VIDEO_COUNT = "DXP_GET_ALL_VIDEO_COUNT";
export const DXP_GET_ALL_VIDEO_COUNT_ERROR = "DXP_GET_ALL_VIDEO_COUNT_ERROR";
export const DXP_UPDATE_VIDEO = "DXP_UPDATE_VIDEO";
export const DXP_UPDATE_VIDEO_ERROR = "DXP_UPDATE_VIDEO_ERROR";
export const DXP_DELETE_VIDEO = "DXP_DELETE_VIDEO";
export const DXP_DELETE_VIDEO_ERROR = "DXP_DELETE_VIDEO_ERROR";
export const DXP_GET_RELATED_CONTENT_VIDEO = "DXP_GET_RELATED_CONTENT_VIDEO";
export const DXP_GET_RELATED_CONTENT_VIDEO_ERROR = "DXP_GET_RELATED_CONTENT_VIDEO_ERROR";
export const DXP_GET_ALL_COURSES_BY_LANGUAGE = " DXP_GET_ALL_COURSES_BY_LANGUAGE";
export const DXP_GET_ALL_COURSES_BY_LANGUAGE_ERROR = "DXP_GET_ALL_COURSES_BY_LANGUAGE_ERROR";
export const DXP_SEARCH_VIDEOS_COUNT = "DXP_SEARCH_VIDEOS_COUNT";
export const DXP_SEARCH_VIDEOS_COUNT_ERROR = "DXP_SEARCH_VIDEOS_COUNT_ERROR";
export const DXP_GET_ALL_VIDEOS_BY_LANGUAGE = "DXP_GET_ALL_VIDEOS_BY_LANGUAGE";
export const DXP_GET_ALL_VIDEOS_BY_LANGUAGE_ERROR = "DXP_GET_ALL_VIDEOS_BY_LANGUAGE_ERROR";
export const DXP_GET_VIDEO = "DXP_GET_VIDEO";
export const DXP_GET_VIDEO_ERROR = "DXP_GET_VIDEO_ERROR";

//articles
export const DXP_ADD_ARTICLE = "DXP_ADD_ARTICLE";
export const DXP_ADD_ARTICLE_ERROR = "DXP_ADD_ARTICLE_ERROR";
export const DXP_GET_ALL_ARTICLES = "DXP_GET_ALL_ARTICLES";
export const DXP_GET_ALL_ARTICLES_ERROR = "DXP_GET_ALL_ARTICLES_ERROR";
export const DXP_GET_ARTICLES_PAGE = "DXP_GET_ARTICLES_PAGE";
export const DXP_GET_ARTICLES_PAGE_ERROR = "DXP_GET_ARTICLES_PAGE_ERROR";
export const DXP_SEARCH_ARTICLES_PAGE = "DXP_SEARCH_ARTICLES_PAGE";
export const DXP_SEARCH_ARTICLES_PAGE_ERROR = "DXP_SEARCH_ARTICLES_PAGE_ERROR";
export const DXP_SELECT_ARTICLE = "DXP_SELECT_ARTICLE";
export const DXP_GET_ALL_ARTICLE_COUNT = "DXP_GET_ALL_ARTICLE_COUNT";
export const DXP_GET_ALL_ARTICLE_COUNT_ERROR = "DXP_GET_ALL_ARTICLE_COUNT_ERROR";
export const DXP_UPDATE_ARTICLE = "DXP_UPDATE_ARTICLE";
export const DXP_UPDATE_ARTICLE_ERROR = "DXP_UPDATE_ARTICLE_ERROR";
export const DXP_DELETE_ARTICLE = "DXP_DELETE_ARTICLE";
export const DXP_DELETE_ARTICLE_ERROR = "DXP_DELETE_ARTICLE_ERROR";
export const DXP_GET_RELATED_CONTENT_ARTICLE = "DXP_GET_RELATED_CONTENT_ARTICLE";
export const DXP_GET_RELATED_CONTENT_ARTICLE_ERROR = "DXP_GET_RELATED_CONTENT_ARTICLE_ERROR";
export const DXP_SEARCH_ARTICLES_COUNT = "DXP_SEARCH_ARTICLES_COUNT";
export const DXP_SEARCH_ARTICLES_COUNT_ERROR = "DXP_SEARCH_ARTICLES_COUNT_ERROR";
export const DXP_GET_ARTICLE_DOWNLOAD_COUNT = "DXP_GET_ARTICLE_DOWNLOAD_COUNT";
export const DXP_GET_ARTICLE_DOWNLOAD_COUNT_ERROR = "DXP_GET_ARTICLE_DOWNLOAD_COUNT_ERROR";

//webinars
export const DXP_ADD_WEBINAR = "DXP_ADD_WEBINAR";
export const DXP_ADD_WEBINAR_ERROR = "DXP_ADD_WEBINAR_ERROR";
export const DXP_GET_ALL_WEBINARS = "DXP_GET_ALL_WEBINARS";
export const DXP_GET_ALL_WEBINARS_ERROR = "DXP_GET_ALL_WEBINARS_ERROR";
export const DXP_GET_WEBINARS_PAGE = "DXP_GET_WEBINARS_PAGE";
export const DXP_GET_WEBINARS_PAGE_ERROR = "DXP_GET_WEBINARS_PAGE_ERROR";
export const DXP_SEARCH_WEBINARS_PAGE = "DXP_SEARCH_WEBINARS_PAGE";
export const DXP_SEARCH_WEBINARS_PAGE_ERROR = "DXP_SEARCH_WEBINARS_PAGE_ERROR";
export const DXP_SELECT_WEBINAR = "DXP_SELECT_WEBINAR";
export const DXP_GET_ALL_WEBINAR_COUNT = "DXP_GET_ALL_WEBINAR_COUNT";
export const DXP_GET_ALL_WEBINAR_COUNT_ERROR = "DXP_GET_ALL_WEBINAR_COUNT_ERROR";
export const DXP_UPDATE_WEBINAR = "DXP_UPDATE_WEBINAR";
export const DXP_UPDATE_WEBINAR_ERROR = "DXP_UPDATE_WEBINAR_ERROR";
export const DXP_DELETE_WEBINAR = "DXP_DELETE_WEBINAR";
export const DXP_DELETE_WEBINAR_ERROR = "DXP_DELETE_WEBINAR_ERROR";
export const DXP_GET_RELATED_CONTENT_WEBINAR = "DXP_GET_RELATED_CONTENT_WEBINAR";
export const DXP_GET_RELATED_CONTENT_WEBINAR_ERROR = "DXP_GET_RELATED_CONTENT_WEBINAR_ERROR";

//EVENTs
export const DXP_GET_EVENT_TYPES = "DXP_GET_EVENT_TYPES";
export const DXP_GET_EVENT_TYPES_ERROR = "DXP_GET_EVENT_TYPES_ERROR";
export const DXP_ADD_EVENT = "DXP_ADD_EVENT";
export const DXP_ADD_EVENT_ERROR = "DXP_ADD_EVENT_ERROR";
export const DXP_GET_ALL_EVENTS = "DXP_GET_ALL_EVENTS";
export const DXP_GET_ALL_EVENTS_ERROR = "DXP_GET_ALL_EVENTS_ERROR";
export const DXP_GET_EVENTS_PAGE = "DXP_GET_EVENTS_PAGE";
export const DXP_GET_EVENTS_PAGE_ERROR = "DXP_GET_EVENTS_PAGE_ERROR";
export const DXP_SEARCH_EVENTS_PAGE = "DXP_SEARCH_EVENTS_PAGE";
export const DXP_SEARCH_EVENTS_PAGE_ERROR = "DXP_SEARCH_EVENTS_PAGE_ERROR";
export const DXP_SELECT_EVENT = "DXP_SELECT_EVENT";
export const DXP_GET_ALL_EVENT_COUNT = "DXP_GET_ALL_EVENT_COUNT";
export const DXP_GET_ALL_EVENT_COUNT_ERROR = "DXP_GET_ALL_EVENT_COUNT_ERROR";
export const DXP_UPDATE_EVENT = "DXP_UPDATE_EVENT";
export const DXP_UPDATE_EVENT_ERROR = "DXP_UPDATE_EVENT_ERROR";
export const DXP_DELETE_EVENT = "DXP_DELETE_EVENT";
export const DXP_DELETE_EVENT_ERROR = "DXP_DELETE_EVENT_ERROR";
export const DXP_SEARCH_EVENTS_COUNT = "DXP_SEARCH_EVENTS_COUNT";
export const DXP_SEARCH_EVENTS_COUNT_ERROR = "DXP_SEARCH_EVENTS_COUNT_ERROR";
export const DXP_GET_ALL_EVENTS_BY_LANGUAGE = "DXP_GET_ALL_EVENTS_BY_LANGUAGE";
export const DXP_GET_ALL_EVENTS_BY_LANGUAGE_ERROR = "DXP_GET_ALL_EVENTS_BY_LANGUAGE_ERROR";
export const DXP_GET_EVENT_OPTIONS = "DXP_GET_EVENT_OPTIONS";
export const DXP_GET_EVENT_OPTIONS_ERROR = "DXP_GET_EVENT_OPTIONS_ERROR";
export const DXP_GET_MANUAL_EVENTS = "DXP_GET_MANUAL_EVENTS";
export const DXP_GET_MANUAL_EVENTS_ERROR = "DXP_GET_MANUAL_EVENTS_ERROR";
export const DXP_SEARCH_MANUAL_EVENTS = "DXP_SEARCH_MANUAL_EVENTS";
export const DXP_SEARCH_MANUAL_EVENTS_ERROR = "DXP_SEARCH_MANUAL_EVENTS_ERROR";
export const DXP_SELECT_MANUAL_EVENT = "DXP_SELECT_MANUAL_EVENT";

//External EVENTs
export const DXP_ADD_EXTERNAL_EVENT = "DXP_ADD_EXTERNAL_EVENT";
export const DXP_ADD_EXTERNAL_EVENT_ERROR = "DXP_ADD_EXTERNAL_EVENT_ERROR";
export const DXP_GET_ALL_EXTERNAL_EVENTS = "DXP_GET_ALL_EXTERNAL_EVENTS";
export const DXP_GET_ALL_EXTERNAL_EVENTS_ERROR = "DXP_GET_ALL_EXTERNAL_EVENTS_ERROR";
export const DXP_GET_EXTERNAL_EVENTS_PAGE = "DXP_GET_EXTERNAL_EVENTS_PAGE";
export const DXP_GET_EXTERNAL_EVENTS_PAGE_ERROR = "DXP_GET_EXTERNAL_EVENTS_PAGE_ERROR";
export const DXP_SEARCH_EXTERNAL_EVENTS_PAGE = "DXP_SEARCH_EXTERNAL_EVENTS_PAGE";
export const DXP_SEARCH_EXTERNAL_EVENTS_PAGE_ERROR = "DXP_SEARCH_EXTERNAL_EVENTS_PAGE_ERROR";
export const DXP_SELECT_EXTERNAL_EVENT = "DXP_SELECT_EXTERNAL_EVENT";
export const DXP_GET_ALL_EXTERNAL_EVENT_COUNT = "DXP_GET_ALL_EXTERNAL_EVENT_COUNT";
export const DXP_GET_ALL_EXTERNAL_EVENT_COUNT_ERROR = "DXP_GET_ALL_EXTERNAL_EVENT_COUNT_ERROR";
export const DXP_UPDATE_EXTERNAL_EVENT = "DXP_UPDATE_EXTERNAL_EVENT";
export const DXP_UPDATE_EXTERNAL_EVENT_ERROR = "DXP_UPDATE_EXTERNAL_EVENT_ERROR";
export const DXP_DELETE_EXTERNAL_EVENT = "DXP_DELETE_EXTERNAL_EVENT";
export const DXP_DELETE_EXTERNAL_EVENT_ERROR = "DXP_DELETE_EXTERNAL_EVENT_ERROR";
export const DXP_SEARCH_EXTERNAL_EVENTS_COUNT = "DXP_SEARCH_EXTERNAL_EVENTS_COUNT";
export const DXP_SEARCH_EXTERNAL_EVENTS_COUNT_ERROR = "DXP_SEARCH_EXTERNAL_EVENTS_COUNT_ERROR";

// languages
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_ERROR = "GET_LANGUAGE_ERROR";
export const DXP_SELECT_LANGUAGE = "DXP_SELECT_LANGUAGE";
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const ADD_LANGUAGE_ERROR = "ADD_LANGUAGE_ERROR";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const UPDATE_LANGUAGE_ERROR = "UPDATE_LANGUAGE_ERROR";
export const DXP_GET_ALL_LANGUAGES = "DXP_GET_ALL_LANGUAGES";
export const DXP_GET_ALL_LANGUAGES_ERROR = "DXP_GET_ALL_LANGUAGES_ERROR";

// contentTypes
export const DXP_GET_ALL_CONTENT_TYPES = "DXP_GET_ALL_CONTENT_TYPES";
export const DXP_GET_ALL_CONTENT_TYPES_ERROR = "DXP_GET_ALL_CONTENT_TYPES_ERROR";

// users
export const DXP_GET_XPERTS = "DXP_GET_XPERTS";
export const DXP_GET_XPERTS_ERROR = "DXP_GET_XPERTS_ERROR";
export const DXP_GET_ALL_USERS = "DXP_GET_ALL_USERS";
export const DXP_GET_ALL_USERS_ERROR = "DXP_GET_ALL_USERS_ERROR";
export const DXP_ADD_USER = "DXP_ADD_USER";
export const DXP_ADD_USER_ERROR = "DXP_ADD_USER_ERROR";
export const DXP_GET_USER = "DXP_GET_USER";
export const DXP_GET_USER_ERROR = "DXP_GET_USER_ERROR";
export const DXP_UPDATE_USER = "DXP_GET_USER";
export const DXP_UPDATE_USER_ERROR = "DXP_GET_USER_ERROR";
export const DXP_GET_USER_BY_MEMBERSHIP = "DXP_GET_USER_BY_MEMBERSHIP";
export const DXP_GET_USER_BY_MEMBERSHIP_ERROR = "DXP_GET_USER_BY_MEMBERSHIP_ERROR";
export const DXP_GET_USER_BY_GROUP = "DXP_GET_USER_BY_GROUP";
export const DXP_GET_USER_BY_GROUP_ERROR = "DXP_GET_USER_BY_GROUP_ERROR";
export const DXP_UPDATE_USER_PASS = "DXP_UPDATE_USER_PASS";
export const DXP_UPDATE_USER_PASS_ERROR = "DXP_UPDATE_USER_PASS_ERROR";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const GET_USERS_COUNT = "GET_USERS_COUNT";
export const GET_USERS_COUNT_ERROR = "GET_USERS_COUNT_ERROR";
export const DPX_GET_ALL_USERS_PAGE = "DPX_GET_ALL_USERS_PAGE";
export const DPX_GET_ALL_USERS_PAGE_ERROR = "DPX_GET_ALL_USERS_PAGE_ERROR";
export const DXP_SEARCH_USERS = "DXP_SEARCH_USERS";
export const DXP_SEARCH_USERS_ERROR = "DXP_SEARCH_USERS_ERROR";
export const DXP_SEARCH_USERS_DASHBOARD = "DXP_SEARCH_USERS_DASHBOARD";
export const DXP_SEARCH_USERS_DASHBOARD_ERROR = "DXP_SEARCH_USERS_DASHBOARD_ERROR";
export const DXP_SEARCH_USER_OPTIONS = "DXP_SEARCH_USER_OPTIONS";
export const DXP_SEARCH_USER_OPTIONS_ERROR = "DXP_SEARCH_USER_OPTIONS_ERROR";
export const DXP_GET_All_XPERTS_PAGINATED = "DXP_GET_All_XPERTS_PAGINATED";
export const DXP_GET_All_XPERTS_PAGINATED_ERROR = "DXP_GET_All_XPERTS_PAGINATED_ERROR";
export const DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED = "DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED";
export const DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED_ERROR = "DXP_GET_ALL_XPERTS_BY_GROUP_PAGINATED_ERROR";
export const DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED = "DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED";
export const DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED_ERROR = "DXP_GET_ALL_XPERTS_BY_SPECIALTY_PAGINATED_ERROR";
export const DXP_SEARCH_XPERTS_PAGINATED = "DXP_SEARCH_XPERTS_PAGINATED";
export const DXP_SEARCH_XPERTS_PAGINATED_ERROR = "DXP_SEARCH_XPERTS_PAGINATED_ERROR";
export const DXP_GET_ALL_USERS_ID_NAME_ONLY = "DXP_GET_ALL_USERS_ID_NAME_ONLY";
export const DXP_GET_ALL_USERS_ID_NAME_ONLY_ERROR = "DXP_GET_ALL_USERS_ID_NAME_ONLY_ERROR";
export const DXP_ADD_BULK_USERS = "DXP_ADD_BULK_USERS";
export const DXP_ADD_BULK_USERS_ERROR = "DXP_ADD_BULK_USERS_ERROR";
export const DXP_DELETE_BULK_USERS = "DXP_DELETE_BULK_USERS";
export const DXP_DELETE_BULK_USERS_ERROR = "DXP_DELETE_BULK_USERS_ERROR";
export const DXP_GET_COMPLIANCE_DOCS = "DXP_GET_COMPLIANCE_DOCS";
export const DXP_GET_COMPLIANCE_DOCS_ERROR = "DXP_GET_COMPLIANCE_DOCS_ERROR";
export const DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID = "DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID";
export const DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID_ERROR = "DXP_UPDATE_USER_MEMBERSHIP_USERTYPEID_ERROR";
export const DXP_UNDO_DOWNGRADE_MEMBERSHIP = "DXP_UNDO_DOWNGRADE_MEMBERSHIP";
export const DXP_UNDO_DOWNGRADE_MEMBERSHIP_ERROR = "DXP_UNDO_DOWNGRADE_MEMBERSHIP_ERROR";
export const DXP_UPDATE_USER_SUBSCRIPTION_STATUS = "DXP_UPDATE_USER_SUBSCRIPTION_STATUS";
export const DXP_UPDATE_USER_SUBSCRIPTION_STATUS_ERROR = "DXP_UPDATE_USER_SUBSCRIPTION_STATUS_ERROR";
export const DXP_UPDATE_ONLY_USER_USERTYPEID = "DXP_UPDATE_ONLY_USER_USERTYPEID";
export const DXP_UPDATE_ONLY_USER_USERTYPEID_ERROR = "DXP_UPDATE_ONLY_USER_USERTYPEID_ERROR";
export const DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE = "DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE";
export const DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE_ERROR = "DXP_UPDATE_ONLY_USER_NEXT_BILLING_DATE_ERROR";


// FeaturedHome
export const DXP_GET_FEATURED_PROFILES = "DXP_GET_FEATURED_PROFILES";
export const DXP_GET_FEATURED_PROFILES_ERROR = "DXP_GET_FEATURED_PROFILES_ERROR";
export const DXP_GET_FEATURED_CATEGORIES = "DXP_GET_FEATURED_CATEGORIES";
export const DXP_GET_FEATURED_CATEGORIES_ERROR = "DXP_GET_FEATURED_CATEGORIES_ERROR";
export const DXP_GET_FEATURED_FREE = "DXP_GET_FEATURED_FREE";
export const DXP_GET_FEATURED_FREE_ERROR = "DXP_GET_FEATURED_FREE_ERROR";
export const DXP_GET_FEATURED_EVENT = "DXP_GET_FEATURED_EVENT";
export const DXP_GET_FEATURED_EVENT_ERROR = "DXP_GET_FEATURED_EVENT_ERROR";
export const DXP_GET_STATS = "DXP_GET_STATS";
export const DXP_GET_STATS_ERROR = "DXP_GET_STATS_ERROR";
export const DXP_SELECT_TOP = "DXP_SELECT_TOP";
export const DXP_SELECT_FREE = "DXP_SELECT_FREE";
export const DXP_ADD_FEATURED_CONTENT = "DXP_ADD_FEATURED_CONTENT";
export const DXP_ADD_FEATURED_CONTENT_ERROR = "DXP_ADD_FEATURED_CONTENT_ERROR";
export const DXP_UPDATE_CONTENT = "DXP_UPDATE_CONTENT";
export const DXP_UPDATE_CONTENT_ERROR = "DXP_UPDATE_CONTENT_ERROR";
export const DXP_DELETE_CONTENT = "DXP_DELETE_CONTENT";
export const DXP_DELETE_CONTENT_ERROR = "DXP_DELETE_CONTENT_ERROR";
export const DXP_SELECT_HOME_EVENT = "DXP_SELECT_HOME_EVENT";
export const DXP_SELECT_HOME_FORUM_POST = "DXP_SELECT_HOME_FORUM_POST";
export const DXP_GET_FEATURED_FORUM_POST = "DXP_GET_FEATURED_FORUM_POST";
export const DXP_GET_FEATURED_FORUM_POST_ERROR = "DXP_GET_FEATURED_FORUM_POST_ERROR";
export const DXP_GET_FEATURED_LIVE_EVENTS = "DXP_GET_FEATURED_LIVE_EVENTS";
export const DXP_GET_FEATURED_LIVE_EVENTS_ERROR = "DXP_GET_FEATURED_LIVE_EVENTS_ERROR";


// FeaturedEvent
export const DXP_UPDATE_FEATURED_EVENT = "DXP_UPDATE_FEATURED_EVENT";
export const DXP_UPDATE_FEATURED_EVENT_ERROR = "DXP_UPDATE_FEATURED_EVENT_ERROR";
export const DXP_ADD_FEATURED_EVENT = "DXP_ADD_FEATURED_EVENT";
export const DXP_ADD_FEATURED_EVENT_ERROR = "DXP_ADD_FEATURED_EVENT_ERROR";
export const DXP_DELETE_FEATURED_EVENT = "DXP_DELETE_FEATURED_EVENT";
export const DXP_DELETE_FEATURED_EVENT_ERROR = "DXP_DELETE_FEATURED_EVENT_ERROR";
export const DXP_GET_TOWER_EVENTS = "DXP_GET_TOWER_EVENTS";
export const DXP_GET_TOWER_EVENTS_ERROR = "DXP_GET_TOWER_EVENTS_ERROR";
export const DXP_GET_TOP_EVENTS = "DXP_GET_TOP_EVENTS";
export const DXP_GET_TOP_EVENTS_ERROR = "DXP_GET_TOP_EVENTS_ERROR";
export const DXP_SELECT_TOP_EVENT = "DXP_SELECT_TOP_EVENT";
export const DXP_SELECT_TOWER_EVENT = "DXP_SELECT_TOWER_EVENT";

// Featured All Content
export const DXP_GET_FEATURED_COURSE = "DXP_GET_FEATURED_COURSE";
export const DXP_GET_FEATURED_COURSE_ERROR = "DXP_GET_FEATURED_COURSE_ERROR";
export const DXP_GET_FEATURED_NEW_ALL = "DXP_GET_FEATURED_NEW_ALL";
export const DXP_GET_FEATURED_NEW_ALL_ERROR = "DXP_GET_FEATURED_NEW_ALL_ERROR";
export const DXP_GET_FEATURED_CONTENT = "DXP_GET_FEATURED_CONTENT";
export const DXP_GET_FEATURED_CONTENT_ERROR = "DXP_GET_FEATURED_CONTENT_ERROR";
export const DXP_GET_FEATURED_CATEGORIES_ALL = "DXP_GET_FEATURED_CATEGORIES_ALL";
export const DXP_GET_FEATURED_CATEGORIES_ALL_ERROR = "DXP_GET_FEATURED_CATEGORIES_ALL_ERROR";
export const DXP_GET_FEATURED_WEBINARS_ALL = "DXP_GET_FEATURED_WEBINARS_ALL";
export const DXP_GET_FEATURED_WEBINARS_ALL_ERROR = "DXP_GET_FEATURED_WEBINARS_ALL_ERROR";
export const DXP_GET_FEATURED_XPERT_ALL = "DXP_GET_FEATURED_XPERT_ALL";
export const DXP_GET_FEATURED_XPERT_ALL_ERROR = "DXP_GET_FEATURED_XPERT_ALL_ERROR";
export const DXP_SELECT_WEBINARS_ALL = "DXP_SELECT_WEBINARS_ALL";
export const DXP_SELECT_XPERT_ALL = "DXP_SELECT_XPERT_ALL";
export const DXP_SELECT_NEW_CONTENT_ALL = "DXP_SELECT_NEW_CONTENT_ALL";
export const DXP_ADD_FEATURED_CONTENT_ALL = "DXP_ADD_FEATURED_CONTENT_ALL";
export const DXP_ADD_FEATURED_CONTENT_ALL_ERROR = "DXP_ADD_FEATURED_CONTENT_ALL_ERROR";
export const DXP_UPDATE_FEATURED_CONTENT_ALL = "DXP_UPDATE_FEATURED_CONTENT_ALL";
export const DXP_UPDATE_FEATURED_CONTENT_ALL_ERROR = "DXP_UPDATE_FEATURED_CONTENT_ALL_ERROR";
export const DXP_DELETE_FEATURED_ALL = "DXP_DELETE_FEATURED_ALL";
export const DXP_DELETE_FEATURED_ALL_ERROR = "DXP_DELETE_FEATURED_ALL_ERROR";

// Featured Free to View
export const DXP_FREE_GET_FEATURED_COURSE = "DXP_FREE_GET_FEATURED_COURSE";
export const DXP_FREE_GET_FEATURED_COURSE_ERROR = "DXP_FREE_GET_FEATURED_COURSE_ERROR";
export const DXP_FREE_GET_FREE_CONTENT = "DXP_FREE_GET_FREE_CONTENT";
export const DXP_FREE_GET_FREE_CONTENT_ERROR = "DXP_FREE_GET_FREE_CONTENT_ERROR";
export const DXP_FREE_GET_FEATURED_CATEGORIES = "DXP_FREE_GET_FEATURED_CATEGORIES";
export const DXP_FREE_GET_FEATURED_CATEGORIES_ERROR = "DXP_FREE_GET_FEATURED_CATEGORIES_ERROR";
export const DXP_FREE_GET_FEATURED_NEW_ALL = "DXP_FREE_GET_FEATURED_NEW_ALL";
export const DXP_FREE_GET_FEATURED_NEW_ALL_ERROR = "DXP_FREE_GET_FEATURED_NEW_ALL_ERROR";
export const DXP_FREE_SELECT_COURSE = "DXP_FREE_SELECT_COURSE";
export const DXP_FREE_SELECT_FREE_CONTENT = "DXP_FREE_SELECT_FREE_CONTENT";
export const DXP_FREE_SELECT_NEW_CONTENT = "DXP_FREE_SELECT_NEW_CONTENT";
export const DXP_FREE_ADD_FEATURED_CONTENT = "DXP_FREE_ADD_FEATURED_CONTENT";
export const DXP_FREE_ADD_FEATURED_CONTENT_ERROR = "DXP_FREE_ADD_FEATURED_CONTENT_ERROR";
export const DXP_FREE_UPDATE_FEATURED_CONTENT = "DXP_FREE_UPDATE_FEATURED_CONTENT";
export const DXP_FREE_UPDATE_FEATURED_CONTENT_ERROR = "DXP_FREE_UPDATE_FEATURED_CONTENT_ERROR";
export const DXP_FREE_DELETE_CONTENT = "DXP_FREE_DELETE_CONTENT";
export const DXP_FREE_DELETE_CONTENT_ERROR = "DXP_FREE_DELETE_CONTENT_ERROR";

// Featured Xperts
export const DXP_XPERT_GET = "DXP_XPERT_GET";
export const DXP_XPERT_GET_ERROR = "DXP_XPERT_GET_ERROR";
export const DXP_XPERT_SELECT = "DXP_XPERT_SELECT";
export const DXP_XPERT_ADD = "DXP_XPERT_ADD";
export const DXP_XPERT_ADD_ERROR = "DXP_XPERT_ADD_ERROR";
export const DXP_XPERT_UPDATE = "DXP_XPERT_UPDATE";
export const DXP_XPERT_UPDATE_ERROR = "DXP_XPERT_UPDATE_ERROR";
export const DXP_XPERT_DELETE = "DXP_XPERT_DELETE";
export const DXP_XPERT_DELETE_ERROR = "DXP_XPERT_DELETE_ERROR";

// Featured Xperts
export const DXP_EXTERNAL_EVENT_GET = "DXP_EXTERNAL_EVENT_GET";
export const DXP_EXTERNAL_EVENT_GET_ERROR = "DXP_EXTERNAL_EVENT_GET_ERROR";
export const DXP_EXTERNAL_EVENT_SELECT = "DXP_EXTERNAL_EVENT_SELECT";
export const DXP_EXTERNAL_EVENT_ADD = "DXP_EXTERNAL_EVENT_ADD";
export const DXP_EXTERNAL_EVENT_ADD_ERROR = "DXP_EXTERNAL_EVENT_ADD_ERROR";
export const DXP_EXTERNAL_EVENT_UPDATE = "DXP_EXTERNAL_EVENT_UPDATE";
export const DXP_EXTERNAL_EVENT_UPDATE_ERROR = "DXP_EXTERNAL_EVENT_UPDATE_ERROR";
export const DXP_EXTERNAL_EVENT_DELETE = "DXP_EXTERNAL_EVENT_DELETE";
export const DXP_EXTERNAL_EVENT_DELETE_ERROR = "DXP_EXTERNAL_EVENT_DELETE_ERROR";

// Featured Manufacturer
export const DXP_MANUFACTURER_GET = "DXP_MANUFACTURER_GET";
export const DXP_MANUFACTURER_GET_ERROR = "DXP_MANUFACTURER_GET_ERROR";
export const DXP_MANUFACTURER_SELECT = "DXP_MANUFACTURER_SELECT";
export const DXP_MANUFACTURER_ADD = "DXP_MANUFACTURER_ADD";
export const DXP_MANUFACTURER_ADD_ERROR = "DXP_MANUFACTURER_ADD_ERROR";
export const DXP_MANUFACTURER_UPDATE = "DXP_MANUFACTURER_UPDATE";
export const DXP_MANUFACTURER_UPDATE_ERROR = "DXP_MANUFACTURER_UPDATE_ERROR";
export const DXP_MANUFACTURER_DELETE = "DXP_MANUFACTURER_DELETE";
export const DXP_MANUFACTURER_DELETE_ERROR = "DXP_MANUFACTURER_DELETE_ERROR";

// Featured Video
export const DXP_VIDEO_GET = "DXP_VIDEO_GET";
export const DXP_VIDEO_GET_ERROR = "DXP_VIDEO_GET_ERROR";
export const DXP_VIDEO_SELECT = "DXP_VIDEO_SELECT";
export const DXP_VIDEO_ADD = "DXP_VIDEO_ADD";
export const DXP_VIDEO_ADD_ERROR = "DXP_VIDEO_ADD_ERROR";
export const DXP_VIDEO_UPDATE = "DXP_VIDEO_UPDATE";
export const DXP_VIDEO_UPDATE_ERROR = "DXP_VIDEO_UPDATE_ERROR";
export const DXP_VIDEO_DELETE = "DXP_VIDEO_DELETE";
export const DXP_VIDEO_DELETE_ERROR = "DXP_VIDEO_DELETE_ERROR";

//  Featured Category
export const DXP_CATEGORY_GET_FEATURED_COURSE = "DXP_CATEGORY_GET_FEATURED_COURSE";
export const DXP_CATEGORY_GET_FEATURED_COURSE_ERROR = "DXP_CATEGORY_GET_FEATURED_COURSE_ERROR";
export const DXP_CATEGORY_GET_CONTENT = "DXP_CATEGORY_GET_CONTENT";
export const DXP_CATEGORY_GET_CONTENT_ERROR = "DXP_CATEGORY_GET_CONTENT_ERROR";
export const DXP_CATEGORY_GET_FEATURED_NEW = "DXP_CATEGORY_GET_FEATURED_NEW";
export const DXP_CATEGORY_GET_FEATURED_NEW_ERROR = "DXP_CATEGORY_GET_FEATURED_NEW_ERROR";
export const DXP_CATEGORY_SELECT_CATEGORY = "DXP_CATEGORY_SELECT_CATEGORY";
export const DXP_CATEGORY_UNSELECT_CATEGORY = "DXP_CATEGORY_UNSELECT_CATEGORY";
export const DXP_CATEGORY_SELECT_COURSE = "DXP_CATEGORY_SELECT_COURSE";
export const DXP_CATEGORY_SELECT_CONTENT = "DXP_CATEGORY_SELECT_CONTENT";
export const DXP_CATEGORY_SELECT_NEW_CONTENT = "DXP_CATEGORY_SELECT_NEW_CONTENT";
export const DXP_CATEGORY_ADD_FEATURED_CONTENT = "DXP_CATEGORY_ADD_FEATURED_CONTENT";
export const DXP_CATEGORY_ADD_FEATURED_CONTENT_ERROR = "DXP_CATEGORY_ADD_FEATURED_CONTENT_ERROR";
export const DXP_CATEGORY_UPDATE_FEATURED_CONTENT = "DXP_CATEGORY_UPDATE_FEATURED_CONTENT";
export const DXP_CATEGORY_UPDATE_FEATURED_CONTENT_ERROR = "DXP_CATEGORY_UPDATE_FEATURED_CONTENT_ERROR";
export const DXP_CATEGORY_DELETE_CONTENT = "DXP_CATEGORY_DELETE_CONTENT";
export const DXP_CATEGORY_DELETE_CONTENT_ERROR = "DXP_CATEGORY_DELETE_CONTENT_ERROR";

// Featured Search
export const DXP_SEARCH_GET = "DXP_SEARCH_GET";
export const DXP_SEARCH_GET_ERROR = "DXP_SEARCH_GET_ERROR";
export const DXP_SEARCH_SELECT = "DXP_SEARCH_SELECT";
export const DXP_SEARCH_ADD = "DXP_SEARCH_ADD";
export const DXP_SEARCH_ADD_ERROR = "DXP_SEARCH_ADD_ERROR";
export const DXP_SEARCH_UPDATE = "DXP_SEARCH_UPDATE";
export const DXP_SEARCH_UPDATE_ERROR = "DXP_SEARCH_UPDATE_ERROR";
export const DXP_SEARCH_DELETE = "DXP_SEARCH_DELETE";
export const DXP_SEARCH_DELETE_ERROR = "DXP_SEARCH_DELETE_ERROR";

// Featured Search
export const DXP_FORUM_GET = "DXP_FORUM_GET";
export const DXP_FORUM_GET_ERROR = "DXP_FORUM_GET_ERROR";
export const DXP_FORUM_SELECT = "DXP_FORUM_SELECT";
export const DXP_FORUM_ADD = "DXP_FORUM_ADD";
export const DXP_FORUM_ADD_ERROR = "DXP_FORUM_ADD_ERROR";
export const DXP_FORUM_UPDATE = "DXP_FORUM_UPDATE";
export const DXP_FORUM_UPDATE_ERROR = "DXP_FORUM_UPDATE_ERROR";
export const DXP_FORUM_DELETE = "DXP_FORUM_DELETE";
export const DXP_FORUM_DELETE_ERROR = "DXP_FORUM_DELETE_ERROR";

// Featured Affiliate
export const DXP_GET_FEATURED_AFFILIATE = "DXP_GET_FEATURED_AFFILIATE";
export const DXP_GET_FEATURED_AFFILIATE_ERROR = "DXP_GET_FEATURED_AFFILIATE_ERROR";

// userTypes
export const DXP_GET_USER_STATS = "DXP_GET_USER_STATS";
export const DXP_GET_USER_STATS_ERROR = "DXP_GET_USER_STATS_ERROR";
export const DXP_GET_USER_STATS_STATIC = "DXP_GET_USER_STATS_STATIC";
export const DXP_GET_USER_STATS_STATIC_ERROR = "DXP_GET_USER_STATS_STATIC_ERROR";
export const DXP_GET_REVENUE_STATS = "DXP_GET_REVENUE_STATS";
export const DXP_GET_REVENUE_STATS_ERROR = "DXP_GET_REVENUE_STATS_ERROR";
export const DXP_GET_ALL_USER_TYPES = "DXP_GET_ALL_USER_TYPES";
export const DXP_GET_ALL_USER_TYPES_ERROR = "DXP_GET_ALL_USER_TYPES_ERROR";
export const DXP_GET_USER_TYPES_AND_COUNT = "DXP_GET_USER_TYPES_AND_COUNT";
export const DXP_GET_USER_TYPES_AND_COUNT_ERROR = "DXP_GET_USER_TYPES_AND_COUNT_ERROR";
export const DXP_GET_USER_TYPE = "DXP_GET_USER_TYPE";
export const DXP_GET_USER_TYPE_ERROR = "DXP_GET_USER_TYPE_ERROR";
export const DXP_ADD_USER_TYPE = "DXP_ADD_USER_TYPE";
export const DXP_ADD_USER_TYPE_ERROR = "DXP_ADD_USER_TYPE_ERROR";
export const DXP_UPDATE_USER_TYPE = "DXP_UPDATE_USER_TYPE";
export const DXP_UPDATE_USER_TYPE_ERROR = "DXP_UPDATE_USER_TYPE_ERROR";
export const DXP_DELETE_USER_TYPE = "DXP_DELETE_USER_TYPE";
export const DXP_DELETE_USER_TYPE_ERROR = "DXP_DELETE_USER_TYPE_ERROR";
export const DXP_GET_USER_STATS_LOADING = "DXP_GET_USER_STATS_LOADING";
export const DXP_GET_USER_STAT_DETAILS = "DXP_GET_USER_STAT_DETAILS";
export const DXP_GET_USER_STAT_DETAILS_ERROR = "DXP_GET_USER_STAT_DETAILS_ERROR";
export const DXP_USER_STAT_DETAILS_LOADING = "DXP_USER_STAT_DETAILS_LOADING";
export const DXP_USER_STAT_DETAILS_OPEN = "DXP_USER_STAT_DETAILS_OPEN";
export const DXP_USER_STAT_TYPE = "DXP_USER_STAT_TYPE";

// Content Maps
export const DXP_UPDATE_CONTENT_MAP = "DXP_UPDATE_CONTENT_MAP";
export const DXP_UPDATE_CONTENT_MAP_ERROR = "DXP_UPDATE_CONTENT_MAP_ERROR";

// User External Content
export const DXP_ADD_USER_EXTERNAL_CONTENT = "DXP_ADD_USER_EXTERNAL_CONTENT";
export const DXP_ADD_USER_EXTERNAL_CONTENT_ERROR = "DXP_ADD_USER_EXTERNAL_CONTENT_ERROR";
export const DXP_UPDATE_USER_EXTERNAL_CONTENT = "DXP_UPDATE_USER_EXTERNAL_CONTENT";
export const DXP_UPDATE_USER_EXTERNAL_CONTENT_ERROR = "DXP_UPDATE_USER_EXTERNAL_CONTENT_ERROR";
export const DXP_DELETE_USER_EXTERNAL_CONTENT = "DXP_DELETE_USER_EXTERNAL_CONTENT";
export const DXP_DELETE_USER_EXTERNAL_CONTENT_ERROR = "DXP_DELETE_USER_EXTERNAL_CONTENT_ERROR";
export const DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT = "DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT";
export const DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT_ERROR = "DXP_PUBLISH_UNPUBLISH_USER_EXTERNAL_CONTENT_ERROR";

// Member Groups
export const DXP_ADD_GROUP = "DXP_ADD_GROUP";
export const DXP_ADD_GROUP_ERROR = "DXP_ADD_GROUP_ERROR";
export const DXP_GET_ALL_GROUPS = "DXP_GET_ALL_GROUPS";
export const DXP_GET_ALL_GROUPS_ERROR = "DXP_GET_ALL_GROUPS_ERROR";
export const DXP_GET_GROUPS_COUNT = "DXP_GET_GROUPS_COUNT";
export const DXP_GET_GROUPS_COUNT_ERROR = "DXP_GET_GROUPS_COUNT_ERROR";
export const DXP_GET_ALL_GROUPS_COUNT = "DXP_GET_ALL_GROUPS_COUNT";
export const DXP_GET_ALL_GROUPS_COUNT_ERROR = "DXP_GET_ALL_GROUPS_COUNT_ERROR";
export const DPX_GET_ALL_GROUPS_PAGINATED = "DPX_GET_ALL_GROUPS_PAGINATED";
export const DPX_GET_ALL_GROUPS_PAGINATED_ERROR = "DPX_GET_ALL_GROUPS_PAGINATED_ERROR";
export const DXP_GET_GROUP_BY_ID = "DXP_GET_GROUP_BY_ID";
export const DXP_GET_GROUP_BY_ID_ERROR = "DXP_GET_GROUP_BY_ID_ERROR";
export const DXP_UPDATE_GROUP = "DXP_UPDATE_GROUP";
export const DXP_UPDATE_GROUP_ERROR = "DXP_UPDATE_GROUP_ERROR";
export const DXP_UPDATE_GROUP_PRICE = "DXP_UPDATE_GROUP_PRICE";
export const DXP_UPDATE_GROUP_PRICE_ERROR = "DXP_UPDATE_GROUP_PRICE_ERROR";
export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";
export const DXP_SEARCH_GROUPS_PAGINATED = "DXP_SEARCH_GROUPS_PAGINATED";
export const DXP_SEARCH_GROUPS_PAGINATED_ERROR = "DXP_SEARCH_GROUPS_PAGINATED_ERROR";
export const DXP_GET_GROUPS_COUNT_BY_SEARCH = "DXP_GET_GROUPS_COUNT_BY_SEARCH";
export const DXP_GET_GROUPS_COUNT_BY_SEARCH_ERROR = "DXP_GET_GROUPS_COUNT_BY_SEARCH_ERROR";
export const DXP_UPDATE_GROUP_ADD_BULK_MEMBERS = "DXP_UPDATE_GROUP_ADD_BULK_MEMBERS";
export const DXP_UPDATE_GROUP_ADD_BULK_MEMBERS_ERROR = "DXP_UPDATE_GROUP_ADD_BULK_MEMBERS_ERROR";
export const DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS = "DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS";
export const DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS_ERROR = "DXP_UPDATE_GROUP_DELETE_BULK_MEMBERS_ERROR";
export const DXP_SELECT_GROUP = "DXP_SELECT_GROUP";
export const DXP_SELECT_GROUP_USER = "DXP_SELECT_GROUP_USER";
export const DXP_GET_GROUP_USERS = "DXP_GET_GROUP_USERS";
export const DXP_GET_GROUP_USERS_ERROR = "DXP_GET_GROUP_USERS_ERROR";
export const DXP_ADD_GROUP_USER = "DXP_ADD_GROUP_USER";
export const DXP_ADD_GROUP_USER_ERROR = "DXP_ADD_GROUP_USER_ERROR";
export const DXP_GET_ALL_GROUP_USERS = "DXP_GET_ALL_GROUP_USERS";
export const DXP_GET_ALL_GROUP_USERS_ERROR = "DXP_GET_ALL_GROUP_USERS_ERROR";
export const DXP_GET_GROUPS_COUNT_BY_DATE = "DXP_GET_GROUPS_COUNT_BY_DATE";
export const DXP_GET_GROUPS_COUNT_BY_DATE_ERROR = "DXP_GET_GROUPS_COUNT_BY_DATE_ERROR";

// Product Categories
export const DXP_ADD_PRODUCT_CATEGORY = "DXP_ADD_PRODUCT_CATEGORY";
export const DXP_ADD_PRODUCT_CATEGORY_ERROR = "DXP_ADD_PRODUCT_CATEGORY_ERROR";
export const DXP_GET_PRODUCT_CATEGORIES = "DXP_GET_PRODUCT_CATEGORIES";
export const DXP_GET_PRODUCT_CATEGORIES_ERROR = "DXP_GET_PRODUCT_CATEGORIES_ERROR";
export const DXP_GET_PRODUCT_CATEGORIES_PAGINATED = "DXP_GET_PRODUCT_CATEGORIES_PAGINATED";
export const DXP_GET_PRODUCT_CATEGORIES_PAGINATED_ERROR = "DXP_GET_PRODUCT_CATEGORIES_PAGINATED_ERROR";
export const DXP_SELECT_PRODUCT_CATEGORY = "DXP_SELECT_PRODUCT_CATEGORY";
export const DXP_GET_PRODUCT_CATEGORY_COUNT = "DXP_GET_PRODUCT_CATEGORY_COUNT";
export const DXP_GET_PRODUCT_CATEGORY_COUNT_ERROR = "DXP_GET_PRODUCT_CATEGORY_COUNT_ERROR";
export const DXP_DELETE_PRODUCT_CATEGORY = "DXP_DELETE_PRODUCT_CATEGORY";
export const DXP_DELETE_PRODUCT_CATEGORY_ERROR = "DXP_DELETE_PRODUCT_CATEGORY_ERROR";

// Product Categories
export const DXP_ADD_PRODUCT_MANUFACTURER = "DXP_ADD_PRODUCT_MANUFACTURER";
export const DXP_ADD_PRODUCT_MANUFACTURER_ERROR = "DXP_ADD_PRODUCT_MANUFACTURER_ERROR";
export const DXP_GET_PRODUCT_MANUFACTURERS = "DXP_GET_PRODUCT_MANUFACTURERS";
export const DXP_GET_PRODUCT_MANUFACTURERS_ERROR = "DXP_GET_PRODUCT_MANUFACTURERS_ERROR";
export const DXP_UPDATE_PRODUCT_MANUFACTURER = "DXP_UPDATE_PRODUCT_MANUFACTURER";
export const DXP_UPDATE_PRODUCT_MANUFACTURER_ERROR = "DXP_UPDATE_PRODUCT_MANUFACTURER_ERROR";

// Pending Students
export const DXP_GET_ALL_PENDING_STUDENTS_PAGINATED = "DXP_GET_ALL_PENDING_STUDENTS_PAGINATED";
export const DXP_GET_ALL_PENDING_STUDENTS_PAGINATED_ERROR = "DXP_GET_ALL_PENDING_STUDENTS_PAGINATED_ERROR";
export const DXP_UPDATE_APPROVE_STUDENT = "DXP_UPDATE_APPROVE_STUDENT";
export const DXP_UPDATE_APPROVE_STUDENT_ERROR = "DXP_UPDATE_APPROVE_STUDENT_ERROR";
export const DXP_UPDATE_REJECT_STUDENT = "DXP_UPDATE_REJECT_STUDENT";
export const DXP_UPDATE_REJECT_STUDENT_ERROR = "DXP_UPDATE_REJECT_STUDENT_ERROR";
export const DXP_SEARCH_PENDING_STUDENTS_PAGINATED = "DXP_SEARCH_PENDING_STUDENTS_PAGINATED";
export const DXP_SEARCH_PENDING_STUDENTS_PAGINATED_ERROR = "DXP_SEARCH_PENDING_STUDENTS_PAGINATED_ERROR";

// Group Coupons
export const DXP_UPDATE_GROUP_COUPON = "DXP_UPDATE_GROUP_COUPON";
export const DXP_UPDATE_GROUP_COUPON_ERROR = "DXP_UPDATE_GROUP_COUPON_ERROR";
export const DELETE_GROUP_COUPON = "DELETE_GROUP_COUPON";
export const DELETE_GROUP_COUPON_ERROR = "DELETE_GROUP_COUPON_ERROR";
export const DXP_ADD_GROUP_COUPON = "DXP_ADD_GROUP_COUPON";
export const DXP_ADD_GROUP_COUPON_ERROR = "DXP_ADD_GROUP_COUPON_ERROR";
export const DXP_UPDATE_ACTIVE_GROUP_COUPON = "DXP_UPDATE_ACTIVE_GROUP_COUPON";
export const DXP_UPDATE_ACTIVE_GROUP_COUPON_ERROR = "DXP_UPDATE_ACTIVE_GROUP_COUPON_ERROR";

// Featured Banner
export const DXP_BANNER_GET = "DXP_BANNER_GET";
export const DXP_BANNER_GET_ERROR = "DXP_BANNER_GET_ERROR";
export const DXP_BANNER_SELECT = "DXP_BANNER_SELECT";
export const DXP_BANNER_ADD = "DXP_BANNER_ADD";
export const DXP_BANNER_ADD_ERROR = "DXP_BANNER_ADD_ERROR";
export const DXP_BANNER_UPDATE = "DXP_BANNER_UPDATE";
export const DXP_BANNER_UPDATE_ERROR = "DXP_BANNER_UPDATE_ERROR";
export const DXP_BANNER_DELETE = "DXP_BANNER_DELETE";
export const DXP_BANNER_DELETE_ERROR = "DXP_BANNER_DELETE_ERROR";

// FAQ
export const DXP_ADD_FAQ = "DXP_ADD_FAQ";
export const DXP_ADD_FAQ_ERROR = "DXP_ADD_FAQ_ERROR";
export const DXP_GET_FAQS = "DXP_GET_FAQS";
export const DXP_GET_FAQS_ERROR = "DXP_GET_FAQS_ERROR";
export const DXP_GET_FAQS_PAGE = "DXP_GET_FAQS_PAGE";
export const DXP_GET_FAQS_PAGE_ERROR = "DXP_GET_FAQS_PAGE_ERROR";
export const DXP_SELECT_FAQ = "DXP_SELECT_FAQ";
export const DXP_GET_ALL_FAQ_COUNT = "DXP_GET_ALL_FAQ_COUNT";
export const DXP_GET_ALL_FAQ_COUNT_ERROR = "DXP_GET_ALL_FAQ_COUNT_ERROR";
export const DXP_UPDATE_FAQ = "DXP_UPDATE_FAQ";
export const DXP_UPDATE_FAQ_ERROR = "DXP_UPDATE_FAQ_ERROR";
export const DXP_DELETE_FAQ = "DXP_DELETE_FAQ";
export const DXP_DELETE_FAQ_ERROR = "DXP_DELETE_FAQ_ERROR";


// Completed Courses
export const DXP_GET_COMPLETED_COURSE_PAGINATED = "DXP_GET_COMPLETED_COURSE_PAGINATED";
export const DXP_GET_COMPLETED_COURSE_PAGINATED_ERROR = "DXP_GET_COMPLETED_COURSE_PAGINATED_ERROR";
export const DXP_GET_COMPLETED_COURSE_PAGINATED_COUNT = "DXP_GET_COMPLETED_COURSE_PAGINATED_COUNT";
export const DXP_GET_COMPLETED_COURSE_PAGINATED_COUNT_ERROR = "DXP_GET_COMPLETED_COURSE_PAGINATED_COUNT_ERROR";
export const DXP_SEARCH_COMPLETED_COURSE_PAGINATED = "DXP_SEARCH_COMPLETED_COURSE_PAGINATED";
export const DXP_SEARCH_COMPLETED_COURSE_PAGINATED_ERROR = "DXP_SEARCH_COMPLETED_COURSE_PAGINATED_ERROR";
export const DXP_SEARCH_COMPLETED_COURSE_COUNT = "DXP_SEARCH_COMPLETED_COURSE_COUNT";
export const DXP_SEARCH_COMPLETED_COURSE_COUNT_ERROR = "DXP_SEARCH_COMPLETED_COURSE_COUNT_ERROR";
export const DXP_GET_COMPLETED_COURSE_COUNT = "DXP_GET_COMPLETED_COURSE_COUNT";
export const DXP_GET_COMPLETED_COURSE_COUNT_ERROR = "DXP_GET_COMPLETED_COURSE_COUNT_ERROR";
export const DXP_SELECT_COMPLETED_COURSE = "DXP_SELECT_COMPLETED_COURSE";
export const DXP_GET_COMPLETED_COURSE_STATS = "DXP_GET_COMPLETED_COURSE_STATS";
export const DXP_GET_COMPLETED_COURSE_STATS_ERROR = "DXP_GET_COMPLETED_COURSE_STATS_ERROR";
export const DXP_GET_ALL_COMPLETED_FELLOWSHIP_COURSES_PAGINATED = "DXP_GET_ALL_COMPLETED_FELLOWSHIP_COURSES_PAGINATED";
export const DXP_GET_ALL_COMPLETED_FELLOWSHIP_COURSES_PAGINATED_ERROR = "DXP_GET_ALL_COMPLETED_FELLOWSHIP_COURSES_PAGINATED_ERROR";
export const DXP_COMPLETED_COURSE_PAGE_LOADING = "DXP_COMPLETED_COURSE_PAGE_LOADING";
export const DXP_MANUALLY_ADD_CERTIFICATE = "DXP_MANUALLY_ADD_CERTIFICATE";
export const DXP_MANUALLY_ADD_CERTIFICATE_ERROR = "DXP_MANUALLY_ADD_CERTIFICATE_ERROR";
export const DXP_GET_COMPLETED_COURSE_DASHBOARD = "DXP_GET_COMPLETED_COURSE_DASHBOARD";
export const DXP_GET_COMPLETED_COURSE_DASHBOARD_ERROR = "DXP_GET_COMPLETED_COURSE_DASHBOARD_ERROR";



// stripe Subscriptions
export const DXP_AUDIT_ALL_SUBSCRIPTIONS = "DXP_AUDIT_ALL_SUBSCRIPTIONS";
export const DXP_AUDIT_ALL_SUBSCRIPTIONS_ERROR = "DXP_AUDIT_ALL_SUBSCRIPTIONS_ERROR";



// Expiring Content
export const DXP_GET_EXPIRING_CONTENT = "DXP_GET_EXPIRING_CONTENT";
export const DXP_GET_EXPIRING_CONTENT_ERROR = "DXP_GET_EXPIRING_CONTENT_ERROR";
export const DXP_EXTEND_EXPIRING_CONTENT = "DXP_EXTEND_EXPIRING_CONTENT";
export const DXP_EXTEND_EXPIRING_CONTENT_ERROR = "DXP_EXTEND_EXPIRING_CONTENT_ERROR";
export const DXP_UPDATE_EXPIRING_CONTENT = "DXP_UPDATE_EXPIRING_CONTENT";
export const DXP_UPDATE_EXPIRING_CONTENT_ERROR = "DXP_UPDATE_EXPIRING_CONTENT_ERROR";
export const DXP_UPDATE_EXPIRING_CONTENT_BULK = "DXP_UPDATE_EXPIRING_CONTENT_BULK";
export const DXP_UPDATE_EXPIRING_CONTENT_BULK_ERROR = "DXP_UPDATE_EXPIRING_CONTENT_BULK_ERROR";
export const DXP_SELECT_EXPIRING_CONTENT = "DXP_SELECT_EXPIRING_CONTENT";



// User Subscriptions
export const DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED = "DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED";
export const DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR = "DXP_GET_ALL_SUBSCRIPTIONS_PAGINATED_ERROR";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID = "DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID_ERROR = "DXP_GET_ALL_SUBSCRIPTIONS_BY_USERID_ERROR";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED = "DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED_ERROR = "DXP_GET_ALL_SUBSCRIPTIONS_BY_STATUS_PAGINATED_ERROR";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED = "DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR = "DXP_GET_ALL_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR";
export const DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW = "DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW";
export const DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR = "DXP_SUBSCRIPTION_CANCEL_AUTO_RENEW_ERROR";
export const DXP_ADD_NEW_SUBSCRIPTION_ENTRY = "DXP_ADD_NEW_SUBSCRIPTION_ENTRY";
export const DXP_ADD_NEW_SUBSCRIPTION_ENTRY_ERROR = "DXP_ADD_NEW_SUBSCRIPTION_ENTRY_ERROR";
export const DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID = "DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID";
export const DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_ERROR = "DXP_GET_SUBSCRIPTION_BY_SUBSCRIPTION_ID_ERROR";
export const DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID = "DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID";
export const DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID_ERROR = "DXP_UPDATE_SUBSCRIPTION_BY_ENTRY_ID_ERROR";
export const DXP_GET_SUBSCRIPTION_COUPONS = "DXP_GET_SUBSCRIPTION_COUPONS";
export const DXP_GET_SUBSCRIPTION_COUPONS_ERROR = "DXP_GET_SUBSCRIPTION_COUPONS_ERROR";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS = "DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS_ERROR = "DXP_GET_ALL_SUBSCRIPTIONS_BY_FILTER_STATUS_ERROR";
export const DXP_SUBSCRIPTIONS_LOADING = "DXP_SUBSCRIPTIONS_LOADING";


// User Transactions
export const DXP_GET_ALL_TRANSACTIONS_PAGINATED = "DXP_GET_ALL_TRANSACTIONS_PAGINATED";
export const DXP_GET_ALL_TRANSACTIONS_PAGINATED_ERROR = "DXP_GET_ALL_TRANSACTIONS_PAGINATED_ERROR";
export const DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED = "DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED";
export const DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED_ERROR = "DXP_GET_TRANSACTIONS_BY_MEMBERSHIP_PAGINATED_ERROR";
export const DXP_GET_SEARCH_TRANSACTIONS_PAGINATED = "DXP_GET_SEARCH_TRANSACTIONS_PAGINATED";
export const DXP_GET_SEARCH_TRANSACTIONS_PAGINATED_ERROR = "DXP_GET_SEARCH_TRANSACTIONS_PAGINATED_ERROR";
export const DXP_GET_STRIPE_INVOICES_TO_SELECT = "DXP_GET_STRIPE_INVOICES_TO_SELECT";
export const DXP_GET_STRIPE_INVOICES_TO_SELECT_ERROR = "DXP_GET_STRIPE_INVOICES_TO_SELECT_ERROR";
export const DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY = "DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY";
export const DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY_ERROR = "DXP_CREATE_NEW_STRIPE_TRANSACTION_ENTRY_ERROR";
export const DXP_RESET_RETURNING_STRIPE_CHARGES_STATE = "DXP_RESET_RETURNING_STRIPE_CHARGES_STATE";
export const DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY = "DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY";
export const DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY_ERROR = "DXP_CREATE_NEW_AUTH_NET_TRANSACTION_ENTRY_ERROR";
export const DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY = "DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY";
export const DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY_ERROR = "DXP_SEARCH_USER_FOR_AUTH_NET_TRANSACTION_ENTRY_ERROR";
export const DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT = "DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT";
export const DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT_ERROR = "DXP_GET_PAYPAL_TRANSACTIONS_TO_SELECT_ERROR";
export const DXP_RESET_RETURNING_PAYPAL_TRANSACTIONS_STATE = "DXP_RESET_RETURNING_PAYPAL_TRANSACTIONS_STATE";
export const DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY = "DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY";
export const DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY_ERROR = "DXP_CREATE_NEW_PAYPAL_TRANSACTION_ENTRY_ERROR";


export const DXP_GET_ALL_TRANSACTIONS_BY_USERID = "DXP_GET_ALL_TRANSACTIONS_BY_USERID";
export const DXP_GET_ALL_TRANSACTIONS_BY_USERID_ERROR = "DXP_GET_ALL_TRANSACTIONS_BY_USERID_ERROR";
export const DXP_REFUND_ANY_TRANSACTION = "DXP_REFUND_ANY_TRANSACTION";
export const DXP_REFUND_ANY_TRANSACTION_ERROR = "DXP_REFUND_ANY_TRANSACTION_ERROR";
export const DXP_GET_TRANSACTION_BY_TRANSACTION_ID = "DXP_GET_TRANSACTION_BY_TRANSACTION_ID";
export const DXP_GET_TRANSACTION_BY_TRANSACTION_ID_ERROR = "DXP_GET_TRANSACTION_BY_TRANSACTION_ID_ERROR";
export const DXP_UPDATE_TRANSACTION_BY_ENTRY_ID = "DXP_UPDATE_TRANSACTION_BY_ENTRY_ID";
export const DXP_UPDATE_TRANSACTION_BY_ENTRY_ID_ERROR = "DXP_UPDATE_TRANSACTION_BY_ENTRY_ID_ERROR";
export const DXP_ADD_NEW_TRANSACTION_ENTRY = "DXP_ADD_NEW_TRANSACTION_ENTRY";
export const DXP_ADD_NEW_TRANSACTION_ENTRY_ERROR = "DXP_ADD_NEW_TRANSACTION_ENTRY_ERROR";


// Pending Posts & Comments
export const DXP_GET_ALL_PENDING_POSTS_PAGINATED = "DXP_GET_ALL_PENDING_POSTS_PAGINATED";
export const DXP_GET_ALL_PENDING_POSTS_PAGINATED_ERROR = "DXP_GET_ALL_PENDING_POSTS_PAGINATED_ERROR";
export const DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED = "DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED";
export const DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED_ERROR = "DXP_GET_ALL_PENDING_POSTS_COMMENTS_PAGINATED_ERROR";
export const DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED = "DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED";
export const DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED_ERROR = "DXP_GET_ALL_PENDING_VIDEO_COMMENTS_PAGINATED_ERROR";
export const DXP_APPROVE_REJECT_PENDING_POST = "DXP_APPROVE_REJECT_PENDING_POST";
export const DXP_APPROVE_REJECT_PENDING_POST_ERROR = "DXP_APPROVE_REJECT_PENDING_POST_ERROR";
export const DXP_GET_SEARCH_POSTS_PAGINATED = "DXP_GET_SEARCH_POSTS_PAGINATED";
export const DXP_GET_SEARCH_POSTS_PAGINATED_ERROR = "DXP_GET_SEARCH_POSTS_PAGINATED_ERROR";
export const DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT = "DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT";
export const DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT_ERROR = "DXP_APPROVE_REJECT_PENDING_VIDEO_COMMENT_ERROR";
export const DXP_GET_SEARCH_POST_COMMENTS_PAGINATED = "DXP_GET_SEARCH_POST_COMMENTS_PAGINATED";
export const DXP_GET_SEARCH_POST_COMMENTS_PAGINATED_ERROR = "DXP_GET_SEARCH_POST_COMMENTS_PAGINATED_ERROR";
export const DXP_APPROVE_REJECT_PENDING_POST_COMMENT = "DXP_APPROVE_REJECT_PENDING_POST_COMMENT";
export const DXP_APPROVE_REJECT_PENDING_POST_COMMENT_ERROR = "DXP_APPROVE_REJECT_PENDING_POST_COMMENT_ERROR";
export const DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED = "DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED";
export const DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED_ERROR = "DXP_GET_SEARCH_VIDEO_COMMENTS_PAGINATED_ERROR";
export const DXP_GET_ALL_REJECTED_POSTS_PAGINATED = "DXP_GET_ALL_REJECTED_POSTS_PAGINATED";
export const DXP_GET_ALL_REJECTED_POSTS_PAGINATED_ERROR = "DXP_GET_ALL_REJECTED_POSTS_PAGINATED_ERROR";
export const DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED = "DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED";
export const DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED_ERROR = "DXP_GET_ALL_REJECTED_POSTS_COMMENTS_PAGINATED_ERROR";
export const DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED = "DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED";
export const DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED_ERROR = "DXP_GET_ALL_REJECTED_VIDEO_COMMENTS_PAGINATED_ERROR";
export const DXP_GET_ALL_FORUM_POSTS = "DXP_GET_ALL_FORUM_POSTS";
export const DXP_GET_ALL_FORUM_POSTS_ERROR = "DXP_GET_ALL_FORUM_POSTS_ERROR";

// 404 and 301 Page
export const DXP_ADD_404_TO_301 = "DXP_ADD_404_TO_301";
export const DXP_ADD_404_TO_301_ERROR = "DXP_ADD_404_TO_301_ERROR";
export const DXP_GET_404_PAGES_PAGINATED = "DXP_GET_404_PAGES_PAGINATED";
export const DXP_GET_404_PAGES_PAGINATED_ERROR = "DXP_GET_404_PAGES_PAGINATED_ERROR";
export const DXP_SELECT_404_PAGE = "DXP_SELECT_404_PAGE";
export const DXP_GET_404_PAGE_COUNT = "DXP_GET_404_PAGE_COUNT";
export const DXP_GET_404_PAGE_COUNT_ERROR = "DXP_GET_404_PAGE_COUNT_ERROR";

// Constant Contact Mailing List
export const DXP_ADD_CONTACTS = "DXP_ADD_CONTACTS";
export const DXP_ADD_CONTACTS_ERROR = "DXP_ADD_CONTACTS_ERROR";
export const DXP_GET_MAILING_LIST = "DXP_GET_MAILING_LIST";
export const DXP_GET_MAILING_LIST_ERROR = "DXP_GET_MAILING_LIST_ERROR";
export const DXP_SELECT_MAILING_CONTACT = "DXP_SELECT_MAILING_CONTACT";
export const DXP_MAILING_LIST_DELETE_CONTACT = "DXP_MAILING_LIST_DELETE_CONTACT";
export const DXP_MAILING_LIST_DELETE_CONTACT_ERROR = "DXP_MAILING_LIST_DELETE_CONTACT_ERROR";
export const DXP_GET_MAILING_LIST_PAGINATED = "DXP_GET_MAILING_LIST_PAGINATED";
export const DXP_GET_MAILING_LIST_PAGINATED_ERROR = "DXP_GET_MAILING_LIST_PAGINATED_ERROR";

// Symposium Registration
export const DXP_GET_SYMPOSIUMS = "DXP_GET_SYMPOSIUMS";
export const DXP_GET_SYMPOSIUMS_ERROR = "DXP_GET_SYMPOSIUMS_ERROR";
export const DXP_GET_SELECT_SYMPOSIUM_REGISTRANT = "DXP_GET_SELECT_SYMPOSIUM_REGISTRANT";
export const DXP_GET_SYMPOSIUM_REGISTRANT_COUNT = "DXP_GET_SYMPOSIUM_REGISTRANT_COUNT";
export const DXP_GET_SYMPOSIUM_REGISTRANT_COUNT_ERROR = "DXP_GET_SYMPOSIUM_REGISTRANT_COUNT_ERROR";
export const DXP_GET_SYMPOSIUM_PAGINATED = "DXP_GET_SYMPOSIUM_PAGINATED";
export const DXP_GET_SYMPOSIUM_PAGINATED_ERROR = "DXP_GET_SYMPOSIUM_PAGINATED_ERROR";
export const DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT = "DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT";
export const DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT_ERROR = "DXP_SEARCH_SYMPOSIUM_REGISTRANT_COUNT_ERROR";
export const DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED = "DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED";
export const DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED_ERROR = "DXP_SEARCH_SYMPOSIUM_REGISTRANT_PAGINATED_ERROR";
export const DXP_SYMPOSIUM_MAIL_CERTS = "DXP_SYMPOSIUM_MAIL_CERTS";
export const DXP_SYMPOSIUM_MAIL_CERTS_ERROR = "DXP_SYMPOSIUM_MAIL_CERTS_ERROR";
export const DXP_GET_SYMPOSIUM_COURSES = "DXP_GET_SYMPOSIUM_COURSES";
export const DXP_GET_SYMPOSIUM_COURSES_ERROR = "DXP_GET_SYMPOSIUM_COURSES_ERROR";
export const DXP_ADD_SYMPOSIUM_CHECKIN = "DXP_ADD_SYMPOSIUM_CHECKIN";
export const DXP_ADD_SYMPOSIUM_CHECKIN_ERROR = "DXP_ADD_SYMPOSIUM_CHECKIN_ERROR";



// External Contributors
export const DXP_GET_EXTERNAL_CONTRIBUTORS = "DXP_GET_EXTERNAL_CONTRIBUTORS";
export const DXP_GET_EXTERNAL_CONTRIBUTORS_ERROR = "DXP_GET_EXTERNAL_CONTRIBUTORS_ERROR";

// Affiliates
export const DXP_GET_AFFILIATES_COUNT = "DXP_GET_AFFILIATES_COUNT";
export const DXP_GET_AFFILIATES_COUNT_ERROR = "DXP_GET_AFFILIATES_COUNT_ERROR";
export const DXP_GET_ALL_AFFILIATES = "DXP_GET_ALL_AFFILIATES";
export const DXP_GET_ALL_AFFILIATES_ERROR = "DXP_GET_ALL_AFFILIATES_ERROR";
export const DXP_GET_AFFILIATES_PAGINATED = "DXP_GET_AFFILIATES_PAGINATED";
export const DXP_GET_AFFILIATES_PAGINATED_ERROR = "DXP_GET_AFFILIATES_PAGINATED_ERROR";
export const DXP_SEARCH_AFFILIATES_PAGINATED = "DXP_SEARCH_AFFILIATES_PAGINATED";
export const DXP_SEARCH_AFFILIATES_PAGINATED_ERROR = "DXP_SEARCH_AFFILIATES_PAGINATED_ERROR";
export const DXP_SEARCH_COUNT_PAGINATED = "DXP_SEARCH_COUNT_PAGINATED";
export const DXP_SEARCH_AFFILIATES_COUNT_ERROR = "DXP_SEARCH_AFFILIATES_COUNT_ERROR";
export const DXP_SELECT_AFFILIATE = "DXP_SELECT_AFFILIATE";
export const DXP_ADD_AFFILIATES = "DXP_ADD_AFFILIATES";
export const DXP_ADD_AFFILIATES_ERROR = "DXP_ADD_AFFILIATES_ERROR";
export const DXP_UPDATE_AFFILIATES = "DXP_UPDATE_AFFILIATES";
export const DXP_UPDATE_AFFILIATES_ERROR = "DXP_UPDATE_AFFILIATES_ERROR";
export const DXP_DELETE_AFFILIATES = "DXP_DELETE_AFFILIATES";
export const DXP_DELETE_AFFILIATES_ERROR = "DXP_DELETE_AFFILIATES_ERROR";
export const DXP_GET_ALL_AFFILIATE_NAMES = "DXP_GET_ALL_AFFILIATE_NAMES";
export const DXP_GET_ALL_AFFILIATE_NAMES_ERROR = "DXP_GET_ALL_AFFILIATE_NAMES_ERROR";
export const DXP_GET_AFFILIATE_EVENTS = "DXP_GET_AFFILIATE_EVENTS";
export const DXP_GET_AFFILIATE_EVENTS_ERROR = "DXP_GET_AFFILIATE_EVENTS_ERROR";


// FELLOWSHIPS
export const DXP_GET_ALL_FELLOWSHIPS = "DXP_GET_ALL_FELLOWSHIPS";
export const DXP_GET_ALL_FELLOWSHIPS_ERROR = "DXP_GET_ALL_FELLOWSHIPS_ERROR";
export const DXP_SEARCH_FELLOWSHIPS = "DXP_SEARCH_FELLOWSHIPS";
export const DXP_SEARCH_FELLOWSHIPS_ERROR = "DXP_SEARCH_FELLOWSHIPS_ERROR";
export const DXP_GET_ALL_FELLOWSHIPS_PAGINATED = "DXP_GET_ALL_FELLOWSHIPS_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIPS_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIPS_PAGINATED_ERROR";
export const DXP_GET_FELLOWSHIP_BY_ID = "DXP_GET_FELLOWSHIP_BY_ID";
export const DXP_GET_FELLOWSHIP_BY_ID_ERROR = "DXP_GET_FELLOWSHIP_BY_ID_ERROR";
export const DXP_UPDATE_FELLOWSHIP = "DXP_UPDATE_FELLOWSHIP";
export const DXP_UPDATE_FELLOWSHIP_ERROR = "DXP_UPDATE_FELLOWSHIP_ERROR";
export const DXP_CREATE_NEW_FELLOWSHIP = "DXP_CREATE_NEW_FELLOWSHIP";
export const DXP_CREATE_NEW_FELLOWSHIP_ERROR = "DXP_CREATE_NEW_FELLOWSHIP_ERROR";
export const DXP_SEARCH_FELLOWSHIPS_PAGINATED = "DXP_SEARCH_FELLOWSHIPS_PAGINATED";
export const DXP_SEARCH_FELLOWSHIPS_PAGINATED_ERROR = "DXP_SEARCH_FELLOWSHIPS_PAGINATED_ERROR";
export const DXP_DELETE_FELLOWSHIP = "DXP_DELETE_FELLOWSHIP";
export const DXP_DELETE_FELLOWSHIP_ERROR = "DXP_DELETE_FELLOWSHIP_ERROR";


// FELLOWSHIP TESTS
export const DXP_CREATE_FELLOWSHIP_TEST = "DXP_CREATE_FELLOWSHIP_TEST";
export const DXP_CREATE_FELLOWSHIP_TEST_ERROR = "DXP_CREATE_FELLOWSHIP_TEST_ERROR";
export const DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED = "DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIP_TESTS_PAGINATED_ERROR";
export const DXP_GET_FELLOWSHIP_TEST_BY_ID = "DXP_GET_FELLOWSHIP_TEST_BY_ID";
export const DXP_GET_FELLOWSHIP_TEST_BY_ID_ERROR = "DXP_GET_FELLOWSHIP_TEST_BY_ID_ERROR";
export const DXP_UPDATE_FELLOWSHIP_TEST_BY_ID = "DXP_UPDATE_FELLOWSHIP_TEST_BY_ID";
export const DXP_UPDATE_FELLOWSHIP_TEST_BY_ID_ERROR = "DXP_UPDATE_FELLOWSHIP_TEST_BY_ID_ERROR";
export const DXP_DELETE_FELLOWSHIP_TEST_BY_ID = "DXP_DELETE_FELLOWSHIP_TEST_BY_ID";
export const DXP_DELETE_FELLOWSHIP_TEST_BY_ID_ERROR = "DXP_DELETE_FELLOWSHIP_TEST_BY_ID_ERROR";
export const DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED = "DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED";
export const DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED_ERROR = "DXP_SEARCH_FELLOWSHIP_TESTS_PAGINATED_ERROR";


// FELLOWSHIP SESSIONS
export const DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED = "DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIP_SESSIONS_PAGINATED_ERROR";
export const DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED = "DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED";
export const DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED_ERROR = "DXP_SEARCH_FELLOWSHIP_SESSIONS_PAGINATED_ERROR";
export const DXP_GET_SESSION_BY_ID = "DXP_GET_SESSION_BY_ID";
export const DXP_GET_SESSION_BY_ID_ERROR = "DXP_GET_SESSION_BY_ID_ERROR";
export const DXP_CREATE_NEW_SESSION = "DXP_CREATE_NEW_SESSION";
export const DXP_CREATE_NEW_SESSION_ERROR = "DXP_CREATE_NEW_SESSION_ERROR";
export const DXP_UPDATE_FELLOWSHIP_SESSION = "DXP_UPDATE_FELLOWSHIP_SESSION";
export const DXP_UPDATE_FELLOWSHIP_SESSION_ERROR = "DXP_UPDATE_FELLOWSHIP_SESSION_ERROR";
export const DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED = "DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED";
export const DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR = "DXP_GET_FILTERED_SESSIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR";
export const DXP_DELETE_FELLOWSHIP_SESSION_BY_ID = "DXP_DELETE_FELLOWSHIP_SESSION_BY_ID";
export const DXP_DELETE_FELLOWSHIP_SESSION_BY_ID_ERROR = "DXP_DELETE_FELLOWSHIP_SESSION_BY_ID_ERROR";
export const DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY = "DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY";
export const DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY_ERROR = "DXP_GET_ALL_SESSION_COURSES_NAME_ID_ONLY_ERROR";


// FELLOWSHIP FACULTY MEMBERS
export const DXP_GET_ALL_FACULTY_MEMBER_OPTIONS = "DXP_GET_ALL_FACULTY_MEMBER_OPTIONS";
export const DXP_GET_ALL_FACULTY_MEMBER_OPTIONS_ERROR = "DXP_GET_ALL_FACULTY_MEMBER_OPTIONS_ERROR";

// FELLOWSHIP ADDITIONAL PROGRAMS
export const DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED = "DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED";
export const DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED_ERROR = "DXP_GET_ALL_ADDITIONAL_PROGRAMS_PAGINATED_ERROR";
export const DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED = "DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED";
export const DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED_ERROR = "DXP_SEARCH_ADDITIONAL_PROGRAMS_BY_TERM_PAGINATED_ERROR";
export const DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED = "DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED";
export const DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED_ERROR = "DXP_GET_FILTERED_ADDITIONAL_PROGRAMS_BY_FELLOWSHIP_ID_PAGINATED_ERROR";
export const DXP_GET_ADDITIONAL_PROGRAM_BY_ID = "DXP_GET_ADDITIONAL_PROGRAM_BY_ID";
export const DXP_GET_ADDITIONAL_PROGRAM_BY_ID_ERROR = "DXP_GET_ADDITIONAL_PROGRAM_BY_ID_ERROR";
export const DXP_CREATE_NEW_ADDITIONAL_PROGRAM = "DXP_CREATE_NEW_ADDITIONAL_PROGRAM";
export const DXP_CREATE_NEW_ADDITIONAL_PROGRAM_ERROR = "DXP_CREATE_NEW_ADDITIONAL_PROGRAM_ERROR";
export const DXP_UPDATE_ADDITIONAL_PROGRAM = "DXP_UPDATE_ADDITIONAL_PROGRAM";
export const DXP_UPDATE_ADDITIONAL_PROGRAM_ERROR = "DXP_UPDATE_ADDITIONAL_PROGRAM_ERROR";
export const DXP_DELETE_ADDITIONAL_PROGRAM = "DXP_DELETE_ADDITIONAL_PROGRAM";
export const DXP_DELETE_ADDITIONAL_PROGRAM_ERROR = "DXP_DELETE_ADDITIONAL_PROGRAM_ERROR";




// FELLOWSHIP SUBSCRIPTIONS
export const DXP_GET_ALL_USER_COMPLETIONS_PAGINATED = "DXP_GET_ALL_USER_COMPLETIONS_PAGINATED";
export const DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_ERROR = "DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_ERROR";
export const DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE = "DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE";
export const DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE_ERROR = "DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_FILTER_VALUE_ERROR";
export const DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM = "DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM";
export const DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM_ERROR = "DXP_GET_ALL_USER_COMPLETIONS_PAGINATED_BY_SEARCH_TERM_ERROR";
export const DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED = "DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_PAGINATED_ERROR";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED = "DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED";
export const DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR = "DXP_GET_ALL_SUBSCRIPTIONS_BY_FELLOWSHIP_ID_PAGINATED_ERROR";
export const DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED = "DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_SEARCH_TERM_PAGINATED_ERROR";
export const DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS = "DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS";
export const DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR = "DXP_REFUND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR";
export const DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID = "DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID";
export const DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID_ERROR = "DXP_GET_ALL_FELLOWSHIP_SUBSCRIPTIONS_BY_USER_ID_ERROR";
export const DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL = "DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL";
export const DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL_ERROR = "DXP_GET_FELLOWSHIP_SUBCRIPTION_CHARGE_RECIEPT_URL_ERROR";
export const DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION = "DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION";
export const DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION_ERROR = "DXP_POST_CREATE_COMPLIMENTARY_SUBSCRIPTION_ERROR";
export const DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS = "DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS";
export const DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR = "DXP_REVOKE_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR";
export const DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS = "DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS";
export const DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR = "DXP_EXTEND_FELLOWSHIP_SUBSCRIPTION_USER_ACCESS_ERROR";
export const DXP_DELETE_USER_COMPLETIONS = "DXP_DELETE_USER_COMPLETIONS";
export const DXP_DELETE_USER_COMPLETIONS_ERROR = "DXP_DELETE_USER_COMPLETIONS_ERROR";



// FELLOWSHIP MATERIALS
export const DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED = "DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_ERROR";
export const DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE = "DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE";
export const DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE_ERROR = "DXP_GET_ALL_FELLOWSHIP_MATERIALS_PAGINATED_BY_FILTER_VALUE_ERROR";
export const DXP_POST_CREATE_FELLOWSHIP_MATERIALS = "DXP_POST_CREATE_FELLOWSHIP_MATERIALS";
export const DXP_POST_CREATE_FELLOWSHIP_MATERIALS_ERROR = "DXP_POST_CREATE_FELLOWSHIP_MATERIALS_ERROR";
export const DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED = "DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED";
export const DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED_ERROR = "DXP_GET_ALL_FELLOWSHIP_MATERIALS_BY_SEARCH_TERM_PAGINATED_ERROR";
export const DXP_DELETE_FELLOWSHIP_MATERIALS = "DXP_DELETE_FELLOWSHIP_MATERIALS";
export const DXP_DELETE_FELLOWSHIP_MATERIALS_ERROR = "DXP_DELETE_FELLOWSHIP_MATERIALS_ERROR";
export const DXP_UPDATE_FELLOWSHIP_MATERIALS = "DXP_UPDATE_FELLOWSHIP_MATERIALS";
export const DXP_UPDATE_FELLOWSHIP_MATERIALS_ERROR = "DXP_UPDATE_FELLOWSHIP_MATERIALS_ERROR";

// REGISTRATION EVENTS
export const DXP_GET_ACCOUNT_REGISTRANTS = "DXP_GET_ACCOUNT_REGISTRANTS";
export const DXP_GET_ACCOUNT_REGISTRANTS_ERROR = "DXP_GET_ACCOUNT_REGISTRANTS_ERROR";
export const DXP_GET_CHECKED_IN_ACCOUNT_REGISTRANTS = "DXP_GET_CHECKED_IN_ACCOUNT_REGISTRANTS";
export const DXP_GET_CHECKED_IN_ACCOUNT_REGISTRANTS_ERROR = "DXP_GET_CHECKED_IN_ACCOUNT_REGISTRANTS_ERROR";
export const DXP_GET_REGISTRANT_ENROLLMENT = "DXP_GET_REGISTRANT_ENROLLMENT";
export const DXP_GET_REGISTRANT_ENROLLMENT_ERROR = "DXP_GET_REGISTRANT_ENROLLMENT_ERROR";
export const DXP_GET_FILTERED_ACCOUNT_REGISTRANTS = "DXP_GET_FILTERED_ACCOUNT_REGISTRANTS";
export const DXP_GET_FILTERED_CHECKED_IN_ACCOUNT_REGISTRANTS = "DXP_GET_FILTERED_CHECKED_IN_ACCOUNT_REGISTRANTS";
export const DXP_GET_FILTERED_ACCOUNT_REGISTRANTS_ERROR = "DXP_GET_FILTERED_ACCOUNT_REGISTRANTS_ERROR";
export const DXP_SELECT_REGISTRANT = "DXP_SELECT_REGISTRANT";
export const DXP_REASSOCIATE_REGISTRANT_ENROLLMENT = "DXP_REASSOCIATE_REGISTRANT_ENROLLMENT";
export const DXP_REASSOCIATE_REGISTRANT_ENROLLMENT_ERROR = "DXP_REASSOCIATE_REGISTRANT_ENROLLMENT_ERROR";
export const DXP_REGISTRANTS_LOADING = "DXP_REGISTRANTS_LOADING";

// REGISTRATION USERS
export const DXP_GET_REGISTRANT_USERS = "DXP_GET_REGISTRANT_USERS";
export const DXP_GET_REGISTRANT_USERS_ERROR = "DXP_GET_REGISTRANT_USERS_ERROR";
export const DXP_GET_REGISTRANT_USERS_COUNT = "DXP_GET_REGISTRANT_USERS_COUNT";
export const DXP_GET_REGISTRANT_USERS_COUNT_ERROR = "DXP_GET_REGISTRANT_USERS_COUNT_ERROR";
export const DXP_SEARCH_REGISTRANT_USERS = "DXP_SEARCH_REGISTRANT_USERS";
export const DXP_SEARCH_REGISTRANT_USERS_ERROR = "DXP_SEARCH_REGISTRANT_USERS_ERROR";
export const DXP_SEARCH_REGISTRANT_USERS_COUNT = "DXP_SEARCH_REGISTRANT_USERS_COUNT";
export const DXP_SEARCH_REGISTRANT_USERS_COUNT_ERROR = "DXP_SEARCH_REGISTRANT_USERS_COUNT_ERROR";
export const DXP_SELECT_REGISTRANT_USER = "DXP_SELECT_REGISTRANT_USER";

// BLOG POSTS
export const DXP_ADD_NEW_BLOG_POST = "DXP_ADD_NEW_BLOG_POST";
export const DXP_ADD_NEW_BLOG_POST_ERROR = "DXP_ADD_NEW_BLOG_POST_ERROR";
export const DXP_SELECT_SINGLE_BLOG_POST = "DXP_SELECT_SINGLE_BLOG_POST";
export const DXP_GET_ALL_BLOG_POSTS_PAGINATED = "DXP_GET_ALL_BLOG_POSTS_PAGINATED";
export const DXP_GET_ALL_BLOG_POSTS_PAGINATED_ERROR = "DXP_GET_ALL_BLOG_POSTS_PAGINATED_ERROR";
export const DXP_GET_ALL_BLOG_POSTS_COUNT = "DXP_GET_ALL_BLOG_POSTS_COUNT";
export const DXP_GET_ALL_BLOG_POSTS_COUNT_ERROR = "DXP_GET_ALL_BLOG_POSTS_COUNT_ERROR";
export const DXP_UPDATE_BLOG_POST = "DXP_UPDATE_BLOG_POST";
export const DXP_UPDATE_BLOG_POST_ERROR = "DXP_UPDATE_BLOG_POST_ERROR";
export const DXP_DELETE_BLOG_POST = "DXP_DELETE_BLOG_POST";
export const DXP_DELETE_BLOG_POST_ERROR = "DXP_DELETE_BLOG_POST_ERROR";
export const DXP_SEARCH_BLOG_POSTS_PAGINATED = "DXP_SEARCH_BLOG_POSTS_PAGINATED";
export const DXP_SEARCH_BLOG_POSTS_PAGINATED_ERROR = "DXP_SEARCH_BLOG_POSTS_PAGINATED_ERROR";
export const DXP_SEARCH_BLOG_POSTS_COUNT = "DXP_SEARCH_BLOG_POSTS_COUNT";
export const DXP_SEARCH_BLOG_POSTS_COUNT_ERROR = "DXP_SEARCH_BLOG_POSTS_COUNT_ERROR";


// AUDIT SUBSCRIPTIONS
export const DXP_CHECK_SUBSCRIPTION_DISCREPANCIES = "DXP_CHECK_SUBSCRIPTION_DISCREPANCIES";
export const DXP_CHECK_SUBSCRIPTION_DISCREPANCIES_ERROR = "DXP_CHECK_SUBSCRIPTION_DISCREPANCIES_ERROR";
export const DXP_CLEAR_ISSUES = "DXP_CLEAR_ISSUES";
export const DXP_CLEAR_ISSUE_ARRAY = "DXP_CLEAR_ISSUES_ARRAY";
export const DXP_SELECT_ISSUES = "DXP_SELECT_ISSUES";
export const DXP_FIX_SUBSCRIPTION_DISCREPANCIES = "DXP_FIX_SUBSCRIPTION_DISCREPANCIES";
export const DXP_FIX_SUBSCRIPTION_DISCREPANCIES_ERROR = "DXP_FIX_SUBSCRIPTION_DISCREPANCIES_ERROR";
export const DXP_SET_AUDIT_CSV = "DXP_SET_AUDIT_CSV";
export const DXP_SET_SUBSCRIPTION_CSV = "DXP_SET_SUBSCRIPTION_CSV";
export const DXP_DISABLE_FIX_ISSUES = "DXP_DISABLE_FIX_ISSUES";
export const DXP_DISABLE_FIX_BUTTONS = "DXP_DISABLE_FIX_BUTTONS";
export const DXP_SET_SUBSCRIPTION_TYPE = "DXP_SET_SUBSCRIPTION_TYPE";

// TRIAL CODES
export const DXP_GET_TRIAL_CODES_PAGINATED = "DXP_GET_TRIAL_CODES_PAGINATED";
export const DXP_GET_TRIAL_CODES_PAGINATED_ERROR = "DXP_GET_TRIAL_CODES_PAGINATED_ERROR";
export const DXP_SELECT_TRIAL_CODE = "DXP_SELECT_TRIAL_CODE";
export const DXP_GET_TRIAL_CODE_COUNT = "DXP_GET_TRIAL_CODE_COUNT";
export const DXP_GET_TRIAL_CODE_COUNT_ERROR = "DXP_GET_TRIAL_CODE_COUNT_ERROR";
export const DXP_DELETE_TRIAL_CODE = "DXP_DELETE_TRIAL_CODE";
export const DXP_DELETE_TRIAL_CODE_ERROR = "DXP_DELETE_TRIAL_CODE_ERROR";

// SUBSCRIPTION CONFIG
export const DXP_GET_SUBSCRIPTION_CONFIG = "DXP_GET_SUBSCRIPTION_CONFIG";
export const DXP_GET_SUBSCRIPTION_CONFIG_ERROR = "DXP_GET_SUBSCRIPTION_CONFIG_ERROR";


// POP-UPS
export const DXP_CREATE_NEW_POP_UP = "DXP_CREATE_NEW_POP_UP";
export const DXP_CREATE_NEW_POP_UP_ERROR = "DXP_CREATE_NEW_POP_UP_ERROR";
export const DXP_GET_ALL_POP_UPS_PAGINATED = "DXP_GET_ALL_POP_UPS_PAGINATED";
export const DXP_GET_ALL_POP_UPS_PAGINATED_ERROR = "DXP_GET_ALL_POP_UPS_PAGINATED_ERROR";
export const DXP_GET_POP_UP_BY_ID = "DXP_GET_POP_UP_BY_ID";
export const DXP_GET_POP_UP_BY_ID_ERROR = "DXP_GET_POP_UP_BY_ID_ERROR";
export const DXP_UPDATE_POP_UP = "DXP_UPDATE_POP_UP";
export const DXP_UPDATE_POP_UP_ERROR = "DXP_UPDATE_POP_UP_ERROR";
export const DXP_DELETE_POP_UP = "DXP_DELETE_POP_UP";
export const DXP_DELETE_POP_UP_ERROR = "DXP_DELETE_POP_UP_ERROR";

// Free Subscriptions
export const DXP_GET_FREE_PROMOS_BY_USER = "DXP_GET_FREE_PROMOS_BY_USER";
export const DXP_GET_FREE_PROMOS_BY_USER_ERROR = "DXP_GET_FREE_PROMOS_BY_USER_ERROR";
export const DXP_ADD_FREE_PROMOS = "DXP_ADD_FREE_PROMOS";
export const DXP_ADD_FREE_PROMOS_ERROR = "DXP_ADD_FREE_PROMOS_ERROR";
export const DXP_GET_FREE_MEMBERSHIPS = "DXP_GET_FREE_MEMBERSHIPS";
export const DXP_GET_FREE_MEMBERSHIPS_ERROR = "DXP_GET_FREE_MEMBERSHIPS_ERROR";
export const DXP_SEARCH_FREE_MEMBERSHIPS = "DXP_SEARCH_FREE_MEMBERSHIPS";
export const DXP_SEARCH_FREE_MEMBERSHIPS_ERROR = "DXP_SEARCH_FREE_MEMBERSHIPS_ERROR";
export const DXP_GET_FREE_MEMBERSHIP_COUNT = "DXP_GET_FREE_MEMBERSHIP_COUNT";
export const DXP_GET_FREE_MEMBERSHIP_COUNT_ERROR = "DXP_GET_FREE_MEMBERSHIP_COUNT_ERROR";
export const DXP_SEARCH_FREE_MEMBERSHIP_COUNT = "DXP_SEARCH_FREE_MEMBERSHIP_COUNT";
export const DXP_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR = "DXP_SEARCH_FREE_MEMBERSHIP_COUNT_ERROR";

// UTM Metadata
export const DXP_GET_UTM_METADATA = "DXP_GET_UTM_METADATA";
export const DXP_GET_UTM_METADATA_ERROR = "DXP_GET_UTM_METADATA_ERROR";

// Sent Emails
export const DXP_GET_SENT_EMAILS = "DXP_GET_SENT_EMAILS";
export const DXP_GET_SENT_EMAILS_ERROR = "DXP_GET_SENT_EMAILS_ERROR";
export const DXP_GET_SENT_EMAILS_COUNT = "DXP_GET_SENT_EMAILS_COUNT";
export const DXP_GET_SENT_EMAILS_COUNT_ERROR = "DXP_GET_SENT_EMAILS_COUNT_ERROR";
export const DXP_SEARCH_SENT_EMAILS = "DXP_SEARCH_SENT_EMAILS";
export const DXP_SEARCH_SENT_EMAILS_ERROR = "DXP_SEARCH_SENT_EMAILS_ERROR";
export const DXP_GET_SEARCH_EMAILS_COUNT = "DXP_GET_SEARCH_EMAILS_COUNT";
export const DXP_GET_SEARCH_EMAILS_COUNT_ERROR = "DXP_GET_SEARCH_EMAILS_COUNT_ERROR";
