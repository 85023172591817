import React, { useState, useEffect } from "react";
// import { withRouter, useRouter } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import { ArrowBack, ArrowDownward, ArrowUpward, Label } from "@mui/icons-material";
import { Trash } from "react-feather";
import { spacing } from '@mui/system';
import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  TextField as MuiTextField,
  Card,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemButton,
} from "@mui/material";

import {
  selectTemplate,
  setSections,
  selectSection,
  selectInput,
  changeInputData,
  saveArticle,
  updateArticle,
  saveTemplate,
  getTemplates,
  getTemplateContent,
  deleteTemplate,
  changeTemplateHistory,
  clearHistory,
  addNewSection,
  updatePreview,
  savePreview,
  clearPreview,
  clearTemplate,
  selectArticleForTemplate,
  selectBlogForTemplate,
  selectEvaluationForTemplate,
  updateEvaluation,
  saveEvaluation,
  updateBlog,
  saveBlog,
  clearArticleTitle
} from "../../redux/actions/articleTemplates";
import { setAlert } from "../../redux/actions/alerts";

import TemplateStyles from "./TemplateStyles";
import TemplateOptions from "./TemplateOptions";

import config from "../../utils/templateConfig";


const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0px;

  > div {
    border-right: 0px;
  }
`;

const TextField = styled(MuiTextField)`
    margin: 0 16px;
    * {
        color: #eeeeee;
        &::before {
            border: none;
        }
    }
   
`;

const TextFieldColor = styled(MuiTextField)`
    margin: 0 16px;

    * {
      height: 30px;
        color: #eeeeee;
        border-bottom: none;
     
    }
   
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const TemplateDiv = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }



  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const TemplateDivButton = styled(ListItemButton)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }



  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const TemplateText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Section = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #eeeeee;
  cursor: pointer;
  * {
    color: #eeeeee;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }



  
    span {
      color: #eeeeee;
    }
  }
`;

const SectionSelect = styled(ListItem)`
  cursor: pointer;
  position: relative;
  padding: 10px 20px 10px 40px;
  &:hover {
    background-color: #EEEEEE;
  }
  &::before {
    content:'';
    width: 10px;
    height: 10px;
    left: 20px;
    border: 1px solid black;
    position: absolute;
    border-radius: 5px;
  }


`;
const SectionSelected = styled(ListItem)`
  cursor: pointer;
  position: relative;
  background-color: #EEEEEE;
  padding: 10px 20px 10px 40px;

  &::before {
    content:'';
    width: 10px;
    height: 10px;
    left: 20px;
    background-color: #2b2b2b;
    border: 1px solid black;
    position: absolute;
    border-radius: 5px;
  }



`;

const DisabledTemplateText = styled(ListItemText)`
  margin: 0;
  cursor: default;
  span {
    color: ${(props) => darken(0.5, props.theme.sidebar.color)};
    font-size: ${(props) => props.theme.typography.body1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;



const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)}
    ${(props) => props.theme.spacing(1)};
  opacity: 0.9;
  display: block;
`;


const UndoImage = styled(Typography)`
  width: 20px;
   * {
    filter: invert(100%) sepia(40%) saturate(78%) hue-rotate(347deg) brightness(113%) contrast(87%);
  }


`;
const UndoImageDisabled = styled(Typography)`
  width: 20px;
   * {
    filter: invert(100%) sepia(40%) saturate(78%) hue-rotate(347deg) brightness(50%) contrast(87%);
  }


`;

const TemplateSidebar = ({
  selectTemplate,
  setSections,
  selectSection,
  selectInput,
  changeInputData,
  setAlert,
  saveArticle,
  updateArticle,
  updateEvaluation,
  saveEvaluation,
  updateBlog,
  saveBlog,
  saveTemplate,
  getTemplates,
  getTemplateContent,
  deleteTemplate,
  changeTemplateHistory,
  clearHistory,
  addNewSection,
  updatePreview,
  savePreview,
  clearPreview,
  clearTemplate,
  clearArticleTitle,
  selectArticleForTemplate,
  selectBlogForTemplate,
  selectEvaluationForTemplate,
  articleTemplate: {
    selectedTemplate,
    selectedArticle,
    selectedEvaluation,
    selectedBlog,
    sections,
    inputtedData,
    hasTemplate,
    templates,
    returnUrl,
    selectedSection,
    selectedInput,
    templateHistory,
    historyIndex,
    templatePreview,
  },
  ...rest }) => {

  const [template, setTemplate] = useState({
    template_name: '',
    template_id: null
  })
  const {
    template_name,
    template_id
  } = template;

  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false)
  const [publish, setPublish] = useState(false)
  const [templateToDelete, setTemplateToDelete] = useState(null)
  const [possibleSelection, setPossibleSelection] = useState(null)
  const [currentSection, setCurrentSection] = useState(null)
  const [possibleRoute, setPossibleRoute] = useState(null)
  const [templateType, setTemplateType] = useState(null)

  useEffect(() => {
    if (templateType) {
      getTemplates(templateType)
    }
  }, [templateType])

  useEffect(() => {
    if (selectedArticle) {
      setTemplateType('article')
      getTemplateContent(selectedArticle?.article_id, 'article')

    } else if (selectedEvaluation) {
      setTemplateType('evaluation')
      getTemplateContent(selectedEvaluation?.product_evaluation_id, 'evaluation')

    } else if (selectedBlog) {
      setTemplateType('blog')
      getTemplateContent(selectedBlog?.entryId, 'blog')

    } else {
      clearTemplate()
    }
  }, [selectedArticle, selectedEvaluation, selectedBlog])

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTemplate(prevTemplate => ({
      ...prevTemplate,
      [name]: value
    }))
  }

  const handleOpen = () => {
    setOpen(true)
    document.body.style.overflow = 'hidden'
  }

  const handleClose = () => {
    setOpen(false)
    document.body.style.overflow = 'unset'
  }

  const addInputData = (section, order, existingData) => {
    if (!section) {
      return
    }
    const existingComponent = existingData[section.component]
    const childrenStyles = section.children_styles_options.map(option => ({ name: option.name, styles: '' }))
    if (existingComponent) {

      return {
        ...existingData,
        [section.component]: [
          ...existingData[section.component],
          {
            component_id: section.component_id,
            component_version: section.component_version,
            order: order,
            children: [...JSON.parse(JSON.stringify(section.children))],
            styles: '',
            children_styles: childrenStyles
          }
        ]
      }

    } else {
      return {
        ...existingData,
        [section.component]: [
          {
            component_id: section.component_id,
            component_version: section.component_version,
            order: order,
            children: [...JSON.parse(JSON.stringify(section.children))],
            styles: '',
            children_styles: childrenStyles
          }
        ]
      }

    }
  }

  const handleAddTemplate = (template) => {
    selectTemplate(template)

    if (template.template_name === 'Custom') {
      return
    }

    setTemplate({
      template_name: template.template_name,
      template_id: template.template_id
    })
    let existingData = { ...inputtedData }
    for (let i = 0; i < template.json?.length; i++) {
      existingData = addInputData(template.json[i], i, existingData)

    }
    changeInputData(existingData)

  }

  const addSection = (section) => {
    // setSections([...sections, section])
    const addedData = addInputData(section, sections.length, inputtedData)
    addNewSection(addedData, [...sections, section])
    handleClose()
  }

  const handleAddSection = () => {
    if (!possibleSelection) {
      return
    }
    addSection(possibleSelection)
    setPossibleSelection(null)
  }

  const removeSection = (section, idx) => {
    let filteredSections = [...sections]
    filteredSections.splice(idx, 1)
    setSections([...filteredSections])
    const existingComponent = inputtedData[section.component]

    let changingData = { ...inputtedData }
    if (existingComponent?.length === 1) {
      delete changingData[section.component]
    } else {
      const inputIndex = existingComponent.findIndex(component => component.order === idx)

      changingData[section.component]?.splice(inputIndex, 1)
    }

    for (let key of Object.keys(changingData)) {
      for (let obj of changingData[key]) {
        if (obj.order > idx) {
          obj.order--
        }

      }
    }
    selectSection(null)
    changeInputData(changingData)
    handleClose()
  }

  const changeOrder = (i, direction) => {
    if ((i === 0 && direction === 'up') || (i === sections.length - 1 && direction === 'down')) {
      return
    }

    const changingArr = [...sections]
    const newIndex = direction === 'up' ? i - 1 : i + 1
    const temp1 = changingArr[newIndex]
    const temp2 = changingArr[i]
    changingArr[newIndex] = temp2
    changingArr[i] = temp1
    setSections([...changingArr])


    const changing = { ...inputtedData }
    for (let key of Object.keys(changing)) {
      for (let obj of changing[key]) {
        if (obj.order === i) {
          obj.order = newIndex
        } else if (obj.order === newIndex) {
          obj.order = i
        }

      }
    }
    changeInputData(changing)
  }

  const handleDeleteTemplate = () => {
    deleteTemplate(templateToDelete?.template_id, templateType)
    setTemplateToDelete(null)
  }

  const handleSubmit = (action) => {
    if (action === 'save' && !template_name) {
      return setAlert('Please fill out template name to save the template', 'danger')
    }

    // const templateCategory = selectedArticle ? 'article' : '';
    const jsonData = {
      template_type: action === 'save' ? template_name : 'custom',
      template_category: templateType,
      template_id: null,
      elements: Object.values(inputtedData),

    }
    const contentId = selectedArticle && selectedArticle?.article_id ? selectedArticle?.article_id : selectedEvaluation && selectedEvaluation?.product_evaluation_id ? selectedEvaluation?.product_evaluation_id : selectedBlog && selectedBlog.entryId ? selectedBlog && selectedBlog.entryId : null

    if (action === 'submit') {
      if (hasTemplate) {
        if (selectedArticle) {
          updateArticle(contentId, jsonData, publish)
        } else if (selectedEvaluation) {
          updateEvaluation(contentId, jsonData, publish)
        } else if (selectedBlog) {
          updateBlog(contentId, jsonData, publish)
        }
      } else {
        if (selectedArticle) {
          saveArticle(contentId, jsonData, publish)
        } else if (selectedEvaluation) {
          saveEvaluation(contentId, jsonData, publish)
        } else if (selectedBlog) {
          saveBlog(contentId, jsonData, publish)
        }
      }
    } else if (action === 'preview') {
      if (hasTemplate) {
        updatePreview(contentId, jsonData, templateType)
      } else {
        savePreview(contentId, jsonData, templateType)
      }
      return
    } else {
      const templateData = {
        template_name: action === 'save' ? template_name : 'custom',
        template_category: templateType,
        json: Object.values(sections)
      }

      saveTemplate(contentId, templateType, jsonData, templateData, publish)
    }
    clearArticleTitle()
    clearHistory()
    selectTemplate(null)
    selectArticleForTemplate(null)
    selectBlogForTemplate(null)
    selectEvaluationForTemplate(null)
    clearTemplate()
    navigate(returnUrl)
  }

  const handleSectionSelect = (section) => {
    const sectionKey = `${section.component}-component-${section.order}`

    if (currentSection) {
      currentSection.setAttribute('data-selected', '')
      currentSection.style.border = '3px solid transparent'
    }

    let selectedComponent = document.getElementById(sectionKey)

    selectedComponent.setAttribute('data-selected', section.component)
    selectedComponent.style.border = '3px solid #265ba5'

    setCurrentSection(selectedComponent)
    selectSection(section)
    selectInput({
      component: section.component,
      index: section.order,
      childIndex: null,
      styles: {}
    })
  }

  const confirmNavigation = (route) => {
    if (sections.length > 0) {
      setPossibleRoute(route)
    } else {
      handleNavigation(route)
    }
  }

  const handleNavigation = (route) => {
    setPossibleRoute(null)
    clearArticleTitle()
    clearHistory()
    selectTemplate(null)
    selectArticleForTemplate(null)
    selectBlogForTemplate(null)
    selectEvaluationForTemplate(null)
    clearTemplate()
    clearPreview()

    if (route === 'home') {
      navigate('/')
    } else if (route === 'back') {

      navigate(returnUrl ? returnUrl : '/')
    }
  }

  const handleChangeHistory = (direction) => {
    if (!(templateHistory?.length > 1) || (direction === 'forward' && historyIndex === null) || (direction === 'forward' && historyIndex === templateHistory?.length - 1) || (direction === 'back' && historyIndex === 0)) {
      return
    }
    let newIndex
    if (direction === 'back') {
      newIndex = historyIndex ? historyIndex - 1 : templateHistory.length - 2
    } else {
      newIndex = historyIndex + 1
    }
    selectSection(null)
    changeTemplateHistory(newIndex)
  }

  const allTemplates = templates?.length > 0 ? [...templates.filter(template => template.template_category === templateType), { template_name: "Custom" }] : [{ template_name: "Custom" }]
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand onClick={() => confirmNavigation('home')}>
        <Box>DPS {templateType === 'article' ? 'Article' : templateType === 'evaluation' ? 'Evaluation' : templateType === 'blog' ? 'Blog' : ''} Templating</Box>
      </Brand>
      {!selectedArticle && !selectedEvaluation && !selectedBlog && !selectedTemplate ? <FormControl
        variant="outlined"
      // style={{ width: "100%", paddingTop: ".5rem", paddingBottom: ".5rem", marginTop: 12 }}
      >
        <InputLabel id="template-type-label" shrink={false} >{templateType ? '' : 'Template Type'}</InputLabel>
        <Select

          labelId="template-type-label"
          id="template-type"
          value={templateType ? templateType : ''}
          name="templateType"
          onChange={(e) => setTemplateType(e.target.value)}
          placeholder='Template Type'
        >
          <MenuItem value={'article'}>Article</MenuItem>
          <MenuItem value={'evaluation'}>Evaluation</MenuItem>
          <MenuItem value={'blog'}>Blog</MenuItem>
        </Select>
      </FormControl>
        : null}

      <Scrollbar>
        <List disablePadding>

          <Items>

            <TemplateDivButton onClick={() => confirmNavigation('back')} >
              <ArrowBack />
              <TemplateText>Back to CMS</TemplateText>
            </TemplateDivButton>

            {selectedTemplate ?
              <div style={{ padding: '0 16px' }}>
                <div style={{ position: 'relative' }}>
                  <div style={{ display: templatePreview ? 'block' : 'none', position: 'absolute', inset: 0, backgroundColor: '#0005', zIndex: 500, margin: '0 -16px' }}></div>

                  <TemplateDivButton style={{ cursor: 'pointer' }} onClick={() => confirmNavigation('switch')}>
                    <TemplateText >Switch Layout</TemplateText>
                  </TemplateDivButton>

                  <TemplateDivButton >
                    <TextField
                      placeholder="Template Name"
                      value={template_name ? template_name : ''}
                      name='template_name'
                      onChange={handleChange}
                    />
                  </TemplateDivButton>

                  <hr style={{ color: '#eeeeee', width: 'calc(100% - 16px)' }} />

                  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                    <Button
                      onClick={() => handleChangeHistory('back')}
                      disabled={!(templateHistory?.length > 1) || historyIndex === 0}
                      style={{ padding: 0 }}
                    >
                      {!(templateHistory?.length > 1) || historyIndex === 0 ?
                        <UndoImageDisabled>
                          <img src={'/static/img/brands/icons8-undo.svg'} width={'20px'} alt='undo' />
                        </UndoImageDisabled>
                        :
                        <UndoImage>
                          <img src={'/static/img/brands/icons8-undo.svg'} width={'20px'} alt='undo' />
                        </UndoImage>
                      }
                    </Button>

                    <Button
                      onClick={() => handleChangeHistory('forward')}
                      disabled={!(templateHistory?.length > 1) || historyIndex === null || historyIndex === templateHistory?.length - 1}
                      style={{ padding: 0 }}
                    >
                      {!(templateHistory?.length > 1) || historyIndex === null || historyIndex === templateHistory?.length - 1 ?
                        <UndoImageDisabled>
                          <img src={'/static/img/brands/icons8-redo.svg'} width={'20px'} alt='redo' />
                        </UndoImageDisabled>
                        :
                        <UndoImage>
                          <img src={'/static/img/brands/icons8-redo.svg'} width={'20px'} alt='redo' />
                        </UndoImage>
                      }
                    </Button>
                  </div>

                  <hr style={{ color: '#eeeeee', width: 'calc(100% - 16px)' }} />
                  <div style={{ overflowY: sections?.length > 10 ? 'scroll' : 'unset', height: sections?.length > 10 ? '500px' : 'auto' }}>

                    {sections.map((section, idx) => (
                      <Section
                        key={`section-${idx}`}
                      >
                        <span
                          style={{ width: '100%' }}
                          onClick={() => handleSectionSelect({ ...section, order: idx })}
                        >
                          {section.component_name}
                        </span>

                        <div
                          style={{ marginLeft: 'auto' }}
                          onClick={() => changeOrder(idx, 'up')}
                        >
                          <ArrowUpward size={10} />
                        </div>

                        <span
                          onClick={() => changeOrder(idx, 'down')}
                        >
                          <ArrowDownward />
                        </span>

                        <span
                          onClick={() => removeSection(section, idx)}
                          style={{ marginLeft: '5px' }}
                        >
                          <Trash />
                        </span>

                      </Section>
                    ))}

                  </div>

                  <button
                    style={{ color: '#eeeeee', textAlign: 'center', backgroundColor: 'transparent', border: 'none', width: '100%', margin: '20px 0 30px' }}
                    onClick={() => handleOpen()}
                  >
                    + Add New Section
                  </button>

                  {selectedSection ?
                    <>
                      <TemplateStyles />
                      <TemplateOptions />
                    </>
                    : null
                  }

                </div>

                <hr style={{ color: '#eeeeee', width: 'calc(100% - 16px)' }} />

                <TemplateText>Save{selectedArticle || selectedEvaluation || selectedBlog ? ' / Submit' : ''}</TemplateText>

                {templatePreview ?
                  <Button
                    variant='contained'
                    style={{ backgroundColor: '#D9D9E9', width: 'calc(100% - 16px)', borderRadius: 0, margin: '5px 8px' }}
                    onClick={() => clearPreview()}

                  >
                    Clear Preview
                  </Button>
                  :
                  selectedArticle || selectedEvaluation || selectedBlog ?
                    <Button
                      variant='contained'
                      style={{ backgroundColor: '#D9D9E9', width: 'calc(100% - 16px)', borderRadius: 0, margin: '5px 8px' }}
                      onClick={() => handleSubmit('preview')}

                    >
                      Preview
                    </Button>
                    : null
                }

                {selectedArticle || selectedEvaluation || selectedBlog ?
                  <Button
                    variant='contained'
                    style={{ backgroundColor: '#726FF8', width: 'calc(100% - 16px)', borderRadius: 0, margin: '5px 8px' }}
                    onClick={() => handleSubmit('submit')}
                  >
                    Submit
                  </Button>
                  : null}

                <Button
                  variant='contained'
                  style={{ backgroundColor: '#1F900D', width: 'calc(100% - 16px)', borderRadius: 0, margin: '5px 8px' }}
                  onClick={() => handleSubmit('save')}
                >
                  Save{selectedArticle || selectedEvaluation || selectedBlog ? ' & Submit' : ''}
                </Button>

                {selectedArticle || selectedEvaluation || selectedBlog ?
                  <InputLabel style={{ color: '#eeeeee', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <TextField
                      type='checkbox'
                      style={{ color: '#eeeeee' }}
                      onClick={() => setPublish(!publish)}
                    />
                    Publish on Submit
                  </InputLabel>
                  : null}


              </div>
              :
              <>

                <SidebarSection>Select Content Layout</SidebarSection>

                {allTemplates.map((template, index) => (

                  <React.Fragment key={index}>

                    {auth.user.hasAccess.includes('blogsAndArticles') ?

                      <TemplateDivButton>
                        <TemplateText
                          onClick={() => handleAddTemplate(template)}
                          style={{ cursor: 'pointer' }}
                        >
                          {template.template_name}
                        </TemplateText>

                        {template.template_name !== 'Custom' ?
                          <Trash onClick={() => setTemplateToDelete(template)} style={{ cursor: 'pointer' }} />
                          : null
                        }

                      </TemplateDivButton>

                      :
                      <TemplateDiv {...rest}>
                        <DisabledTemplateText>{template.template_name}</DisabledTemplateText>
                      </TemplateDiv>
                    }
                  </React.Fragment>
                ))}
              </>
            }
          </Items>
        </List>

      </Scrollbar >


      {/* Add section Modal */}
      < div style={{ position: 'fixed', inset: 0, backgroundColor: '#0005', display: open ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ width: '300px', padding: 0 }}>

          <h4 style={{ textAlign: 'center' }}>Select a Component</h4>

          {config?.element_key?.filter(elem => !config.element_key.find(e => e.component_id === elem.component_id && +e.component_version > +elem.component_version))?.filter(el => el.templates_categories_available.includes(templateType))?.map((elem, idx) => {
            return possibleSelection?.component_id === elem?.component_id ?
              <SectionSelected key={`template-${idx}`} onClick={() => setPossibleSelection(elem)} style={{ cursor: 'pointer' }}>{elem.component_name}</SectionSelected>
              :
              <SectionSelect key={`template-${idx}`} onClick={() => setPossibleSelection(elem)} style={{ cursor: 'pointer' }}>{elem.component_name}</SectionSelect>
          }
          )}

          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
            <Button style={{ marginRight: '20px' }} variant='outlined' color='primary' onClick={() => handleClose()}>Cancel</Button>
            <Button variant='contained' color='primary' onClick={() => handleAddSection()}>Confirm</Button>

          </div>
        </Card>
      </div >

      {/* Delete Template Modal */}
      < div style={{ position: 'fixed', inset: 0, backgroundColor: '#0005', display: templateToDelete ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ width: '300px', padding: '20px' }}>
          <div>
            Are you sure you want to delete {templateToDelete?.template_name}?

            <p><strong>This cannot be undone.</strong></p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button style={{ marginRight: '20px' }} color='primary' onClick={() => setTemplateToDelete(null)}>Cancel</Button>
            <Button color='primary' onClick={() => handleDeleteTemplate()}>Delete</Button>
          </div>
        </Card>
      </div >

      {/* Navigation Warning Modal */}
      < div style={{ position: 'fixed', inset: 0, backgroundColor: '#0005', display: possibleRoute ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ width: '300px', padding: '20px' }}>
          <div>
            Are you sure you want to leave?

            <p><strong>All changes will be lost</strong></p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button style={{ marginRight: '20px' }} color='primary' onClick={() => setPossibleRoute(null)}>Cancel</Button>
            <Button color='primary' onClick={() => handleNavigation(possibleRoute)}>Continue</Button>
          </div>
        </Card>
      </div >


    </Drawer >
  );
};

// export default withRouter(Sidebar);
const mapStateToProps = (state) => ({
  articleTemplate: state.articleTemplate
});

export default connect(mapStateToProps, {
  selectTemplate,
  setSections,
  selectSection,
  selectInput,
  changeInputData,
  setAlert,
  saveArticle,
  updateArticle,
  saveTemplate,
  getTemplates,
  getTemplateContent,
  deleteTemplate,
  changeTemplateHistory,
  clearHistory,
  addNewSection,
  updatePreview,
  savePreview,
  clearPreview,
  clearTemplate,
  selectArticleForTemplate,
  selectBlogForTemplate,
  selectEvaluationForTemplate,
  updateEvaluation,
  saveEvaluation,
  updateBlog,
  saveBlog,
  clearArticleTitle
})(TemplateSidebar);
// export default withRouter(connect(mapStateToProps, {
//   selectTemplate,
//   setSections,
//   selectSection,
//   selectInput,
//   changeInputData,
//   setAlert,
//   saveArticle,
//   updateArticle,
//   saveTemplate,
//   getTemplates,
//   getTemplateContent,
//   deleteTemplate,
//   changeTemplateHistory,
//   clearHistory,
//   addNewSection,
//   updatePreview,
//   savePreview,
//   clearPreview,
//   clearTemplate,
//   selectArticleForTemplate,
//   selectEvaluationForTemplate,
//   updateEvaluation,
//   saveEvaluation,
//   clearArticleTitle
// })(TemplateSidebar));