
import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import { darken } from "polished";
import { Link } from "react-router-dom";

import {
    Grid2 as Grid,
    InputBase,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Toolbar,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TocIcon from '@mui/icons-material/Toc';

import NextSectionButton from "./NextSectionButton";
import SettingsModal from "./SettingsModal";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const AppBarComponent = ({ onDrawerToggle }) => {
    const [openSettings, setOpenSettings] = useState(false)
    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container sx={{ width: '100%' }} alignItems="center" justifyContent="space-between">
                        <Link
                            to={`/buyers-guide`}
                        >
                            <div style={{ display: 'flex', alignItem: 'center' }}>
                                <ArrowBackIcon />
                                <p style={{ margin: 0, marginLeft: 10, color: 'black', textDecoration: 'none' }}>Back To CMS</p>
                            </div>
                        </Link>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                            <div style={{ cursor: 'pointer' }} onClick={() => { setOpenSettings(true) }}>
                                <TocIcon />
                            </div>
                            <NextSectionButton />
                        </div>
                    </Grid>
                </Toolbar>
            </AppBar>
            <SettingsModal
                open={openSettings}
                closeModal={() => { setOpenSettings(false) }}
            />
        </React.Fragment>
    )
};

export default withTheme(AppBarComponent);
