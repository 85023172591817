import React, { useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// import DateFnsUtils from "@date-io/date-fns";
// import { useNavigate } from "react-router-dom";

import { jwtDecode } from "jwt-decode";

import { ThemeProvider } from "styled-components";
// import { create } from "jss";

// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
// import {
//   StylesProvider,
//   jssPreset,
// } from '@mui/material/styles';

import createTheme from "./theme";
import Routes from "./routes/Routes";
import setAuthToken from "./utils/setAuthToken";

import Alert from "./layouts/Alert";

import { getUser } from "./redux/actions/auth";
import store from "./redux/store";

// const jss = create({
//   ...jssPreset(),
//   insertionPoint: document.getElementById("jss-insertion-point"),
// });

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const theme = useSelector((state) => state.themeReducer);
  // const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      return
    }

    store.dispatch(getUser());

    function isAuthenticated() {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      try {

        const loadedToken = refreshToken ? jwtDecode(refreshToken) : token ? jwtDecode(token) : null;

        if (!loadedToken || Date.now() >= loadedToken.exp * 1000) {

          window.location.replace('/')
        }
      } catch (err) {
        console.error(err)
        return false;
      }
      return true;
    }
    isAuthenticated();
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s | Material App"
        defaultTitle="Material App - React Admin & Dashboard Template"
      />
      {/* <StylesProvider jss={jss}> */}
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
      <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
        <ThemeProvider theme={createTheme(theme.currentTheme)}>
          <Alert />
          <Routes />
        </ThemeProvider>
      </MuiThemeProvider>
      {/* </MuiPickersUtilsProvider> */}
      {/* </StylesProvider> */}
    </>
  );
}

export default App;
