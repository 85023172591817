import React from "react";
import { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import styled from "styled-components";

import {
  CardContent,
  Grid2 as Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { spacing } from '@mui/system';
import Loader from "../../components/Loader";

import {
  getProducts,
  searchPublishedProducts,
  getNewDefaultProducts,
  setFeaturedDefaultProduct,
  setFeaturedDefaultFalse,
} from "../../redux/actions/products";
import { setAlert } from "../../redux/actions/alerts";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)`
  width: 270px;
`;

const DefaultProductForm = ({
  getProducts,
  searchPublishedProducts,
  setAlert,
  getNewDefaultProducts,
  setFeaturedDefaultProduct,
  setFeaturedDefaultFalse,
  product: { products, productSearch, isLoading, defaultNewProducts },
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const prodName1 = useRef();
  const prodName2 = useRef();
  const prodName3 = useRef();
  const prodName4 = useRef();

  const [formValues, setFormValues] = useState({
    prod1: {
      id: null,
    },
    prod2: {
      id: null,
    },
    prod3: {
      id: null,
    },
    prod4: {
      id: null,
    },
  });

  useEffect(() => {
    defaultNewProducts.length > 3 &&
      setFormValues({
        prod1: {
          id: defaultNewProducts[0].product_id,
          name: defaultNewProducts[0].name,
        },
        prod2: {
          id: defaultNewProducts[1].product_id,
          name: defaultNewProducts[1].name,
        },
        prod3: {
          id: defaultNewProducts[2].product_id,
          name: defaultNewProducts[2].name,
        },
        prod4: {
          id: defaultNewProducts[3].product_id,
          name: defaultNewProducts[3].name,
        },
      });
  }, [defaultNewProducts]);

  const validateFields = () => {
    if (
      !(
        formValues.prod1["id"] &&
        formValues.prod2["id"] &&
        formValues.prod3["id"] &&
        formValues.prod4["id"]
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name.substring(0, 5)]: {
        ...formValues[e.target.name.substring(0, 5)],
        [e.target.name]: e.target.value,
      },
    });
  };

  const searchForProducts = (e) => {
    setTimeout(() => {
      if (e.target.value.length < 3) {
        setAlert("Query must be 3 characters or longer", "danger");
      } else {
        searchPublishedProducts(e.target.value);
      }
    }, 1000);
  };

  const handleProduct1Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod1: {
          ...formValues["prod1"],
          id: product.product_id,
        },
      });
    }
  };

  const handleProduct2Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod2: {
          ...formValues["prod2"],
          id: product.product_id,
        },
      });
    }
  };

  const handleProduct3Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod3: {
          ...formValues["prod3"],
          id: product.product_id,
        },
      });
    }
  };

  const handleProduct4Select = (e, product) => {
    if (product) {
      setFormValues({
        ...formValues,
        prod4: {
          ...formValues["prod4"],
          id: product.product_id,
        },
      });
    }
  };

  const handleSubmit = async () => {
    if (validateFields()) {
      setAlert("Please fill out all fields below");
    } else {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 3000);
      await setFeaturedDefaultFalse();
      let productId;
      // Loops through the formValue state and fires off requests for each product
      // formValues[product][`${product}-select`]  will dynamically grab the specific properties on the formValue obj
      for (const product in formValues) {
        if (Object.keys(formValues)) {
          productId = formValues[product].id;
          setFeaturedDefaultProduct(productId);
        }
      }
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Card mb={6} variant="outlined" style={{ boxShadow: "5px 5px 5px grey" }}>
      <CardContent>
        <Typography
          variant="h2"
          gutterBottom
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Default Products - New Product Page
        </Typography>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 1
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod1-name1"
                ref={prodName1}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct1Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod1["name"] || ''}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 2
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod2-name1"
                ref={prodName2}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct2Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod2["name"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 3
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod3-name1"
                ref={prodName3}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct3Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod3["name"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>
        <Grid>
          <Paper mt={3}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                backgroundColor: "#e1e1e1",
                padding: "5px",
                paddingLeft: "30px",
                margin: "0px -20px",
              }}
            >
              Product 4
            </Typography>
            <form
              noValidate
              autoComplete="off"
              style={{ margin: "10px", display: "flex", flexWrap: "wrap" }}
            >
              <Autocomplete
                name="prod4-name1"
                ref={prodName4}
                options={productSearch ? productSearch : []}
                getOptionLabel={(option) => option.name}
                style={{ marginRight: "20px" }}
                onKeyUp={(e) => searchForProducts(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Product"
                    variant="outlined"
                  />
                )}
                onChange={(e, prod) => handleProduct4Select(e, prod)}
              />
              <TextField
                variant='standard'
                label="Current Featured Product Name"
                type="text"
                value={formValues.prod4["name"] || ""}
                slotProps={{
                  inputLabel: {
                    shrink: true
                  }
                }}
                disabled
                onChange={handleChange}
                style={{ marginTop: "8px" }}
              />
            </form>
          </Paper>
        </Grid>

        <Divider />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "20px" }}
          onClick={() => handleSubmit()}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
});

export default connect(mapStateToProps, {
  getProducts,
  searchPublishedProducts,
  setAlert,
  getNewDefaultProducts,
  setFeaturedDefaultProduct,
  setFeaturedDefaultFalse,
})(DefaultProductForm);
